import React, { useState, createContext } from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from '@assets/icons/ic_error.svg';
import clsx from 'clsx';
import { useIsLandscape } from '@hooks/useIsLandscape';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  paper: {
    marginTop: 24,
    marginBottom: 24,
    padding: 24,
    maxWidth: '100%',
    maxHeight: 398,
    overflowY: (props) =>
      props.isSignUpPage && !props.isLandscape ? 'scroll' : 'hidden',
    position: 'relative',
    overflowX: 'hidden',
    [breakpoints.up('sm')]: {
      maxHeight: '100%',
      overflowY: 'hidden !important',
    },
    [breakpoints.up('md')]: {
      padding: 32,
      marginTop: 25,
      marginBottom: 35,
    },
  },
  modalPaper: {
    width: '100%',
    paddingBottom: 16,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    padding: '8.5px 0',
    borderRadius: 6,
    backgroundColor: alpha(palette.red.main, 0.08),
    marginBottom: 8,
    [breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
}));

const formContext = createContext();
formContext.displayName = 'AuthFormContext';

const FormError = ({ errorMessage }) => {
  const { errorContainer } = useStyles();
  if (!errorMessage) return null;
  return (
    <div className={errorContainer}>
      <ErrorIcon style={{ marginRight: '8px' }} />
      <Typography variant="h3" className="h3 red">
        {errorMessage}
      </Typography>
    </div>
  );
};

const Form = ({ title, children, isModal }) => {
  const isSignUpPage = useLocation().pathname === '/signup';
  const isLandscape = useIsLandscape();
  const { paper, modalPaper } = useStyles({ isSignUpPage, isLandscape });
  const [error, setError] = useState(null);
  const [onSubmit, handleSubmit] = useState(() => () => {});

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  const contextValue = {
    setError,
    errorMessage: error?.message,
    error,
    handleSubmit,
  };

  return (
    <formContext.Provider value={contextValue}>
      <Paper
        className={isModal ? modalPaper : clsx(paper, 'box-shadow-2')}
        elevation={0}
      >
        {title && (
          <Typography variant="h2" className="h2 black">
            {title}
          </Typography>
        )}
        <form onSubmit={handleFormSubmit}>{children}</form>
      </Paper>
    </formContext.Provider>
  );
};

export { Form, FormError, formContext };
