import { Box, Typography } from '@material-ui/core';
import { ReactComponent as NoSearchResultsIcon } from '@assets/icons/ic_no-results.svg';

export const NoSearchResults = ({ text }) => (
  <Box
    height="calc(100% - 100px)"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    data-testid="no-search-results"
  >
    <NoSearchResultsIcon />
    <Typography
      variant="h3"
      className="display-3"
      style={{ marginTop: '32px' }}
    >
      {text}
    </Typography>
  </Box>
);
