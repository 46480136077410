import React from 'react';
import loaderImg from '@src/img/loader-icon.png';
import Box from '@material-ui/core/Box';

export const Loader = () => (
  <Box
    display="flex"
    height="100%"
    alignItems="center"
    justifyContent="center"
    data-testid="loader"
  >
    <img
      src={loaderImg}
      alt="loader"
      className="loading"
      width="42px"
      height="42px"
    />
  </Box>
);
