import React, { useEffect } from 'react';
import { makeStyles, Paper, Button, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useAuthContext } from '@hooks/useAuthContext';
import { useToasterContext } from '@src/hooks/useToasterContext';
import { ReactComponent as ToastErrorIcon } from '@assets/icons/ic_tooltip.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/orange-check-icon.svg';
import { db } from '@config/firebase';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    background: 'rgba(241, 166, 53, 0.08)',
    position: 'relative',
    top: 0,
    left: 0,
  },
  text: {
    lineHeight: '18px',
    letterSpacing: '0.24px',
  },
  button: {
    textDecoration: 'underline',
    marginLeft: 'auto',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const VerifyEmail = () => {
  const { paper, text, button } = useStyles();
  const { sendVerificationEmail, user } = useAuthContext();

  const { showToaster } = useToasterContext();

  useEffect(() => {
    async function sendVerificationEmailToUser() {
      try {
        const userDoc = await db.collection('accounts').doc(user.uid).get();
        const userData = userDoc.data();
        const { verificationEmailSent } = { ...userData };
        if (!verificationEmailSent) {
          await sendVerificationEmail(user);
        }
      } catch (error) {
        showToaster({
          message: 'Error sending verification email',
          icon: <ToastErrorIcon />,
          type: 'error',
        });
      }
    }
    if (user?.uid) {
      sendVerificationEmailToUser();
    }
  }, [user?.uid]);

  const handleVerifyEmail = async () => {
    try {
      await sendVerificationEmail(user);
    } catch (error) {
      console.error(error);
      showToaster({
        message: 'Error sending verification email, please try again later',
        icon: <ToastErrorIcon />,
      });
    }
  };

  if (user.emailVerified) {
    return null;
  }

  return (
    <Paper
      className={clsx(paper, 'box-shadow-2')}
      data-testid="verify-email"
      id="verify-email"
    >
      <CheckIcon />
      <Box>
        <Typography variant="h4" component="h4" className={clsx('h4', text)}>
          Verify your account!
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={clsx('p1', 'extra-dark-grey', text)}
        >
          A verification email is on its way! Confirm your email to secure your
          account.
        </Typography>
      </Box>
      <Button
        variant="text"
        color="primary"
        className={clsx(button, 'h4')}
        onClick={handleVerifyEmail}
        data-testid="verify-email-button"
      >
        Resend verification
      </Button>
    </Paper>
  );
};

export default VerifyEmail;
