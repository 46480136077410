// this hook is returing the user doc from the database
// we can reuse it inside any FN component and it will be cached for 2 minutes
// so we can use it in any component without calling the database again
import { useQuery } from 'react-query';
import { auth, db, storage } from '@config/firebase';
import { capitalize } from '@utils/capitalize';
import { TWO_MINS, FIVE_SECONDS } from './constants';

async function fetchUserDoc(userId) {
  if (userId) {
    const userDoc = await db.collection('accounts').doc(userId).get();
    const userData = userDoc.data();
    const userToken = await auth.currentUser?.getIdTokenResult(true);
    const avatarRequestsRef = userDoc.ref.collection('avatarRequests');
    const avatarRequestsRes = await avatarRequestsRef.get();
    const avatarRequests = avatarRequestsRes.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    const { accountType, nextAccountType, clientId } = {
      ...userData,
    };
    // means the user initiated a plan pause
    const planPause = nextAccountType === 'BASIC';
    const noPlanChangeList = ['BASIC', 'LOCKED', accountType];
    const planChange =
      nextAccountType && !noPlanChangeList.includes(nextAccountType);

    const clientIdDoc = await db.collection('clientIds').doc(userId).get();
    const { profilePicUrl = '', shortLink } = { ...clientIdDoc.data() };
    let qrImgUrl = '';
    const hasSelectedUsageReason = Object.entries(userData).some(
      ([key, value]) =>
        // checks if user has personal or business usage reason set to true
        (key === 'personal' || key === 'business') && value === true
    );
    try {
      const qrImgRef = storage.child(`org_profiles/${clientId}/qrCode`);
      // if user doens't have a qr code, this will throw an error 👇
      const storageUrl = await qrImgRef.getDownloadURL();
      const forwardedUrl = `${process.env.REACT_APP_SERVE_QR_FN}?url=${storageUrl}`;
      qrImgUrl = forwardedUrl;
    } catch (error) {
      // catch the error so the app will not crash, and don't show the QR card
      console.log('qr code not found');
    }
    return {
      ...userData,
      name: userData?.name ? capitalize(userData.name) : 'No Name',
      avatarRequests,
      isAdmin: userToken.claims.admin,
      uid: userId,
      userDocRef: userDoc.ref,
      planPause,
      planChange,
      profilePicUrl,
      qrLink: shortLink,
      qrImage: qrImgUrl,
      hasSelectedUsageReason,
    };
  }
  return null;
}

function useUser() {
  const { uid } = { ...auth.currentUser };
  return useQuery('user', () => fetchUserDoc(uid), {
    enabled: !!uid,
    staleTime: window.Cypress ? FIVE_SECONDS : TWO_MINS,
    refetchOnWindowFocus: true,
    refetchInterval: window.Cypress ? FIVE_SECONDS : TWO_MINS,
  });
}
export { fetchUserDoc, useUser };
