import { createContext, useState } from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';

const ToasterContext = createContext();
ToasterContext.displayName = 'ToasterModalContext';

const useStyles = makeStyles((theme) => ({
  toaster: {
    backgroundColor: theme.palette.extraDarkGrey.main,
    padding: '8px 24px',
    borderRadius: 6,
    boxShadow: '0px 10px 24px -16px rgba(14, 26, 68, 0.16)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    '& svg': {
      '& path': {
        fill: '#fff',
      },
    },
  },
}));

const Toaster = ({ open, onClose, message, icon }) => {
  const { toaster } = useStyles();
  if (!open) return null;
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div className={toaster}>
        {icon && icon}
        <span className="p1" style={{ color: '#fff' }}>
          {message}
        </span>
      </div>
    </Snackbar>
  );
};

const ToasterContextProvider = ({ children }) => {
  const [toaster, setToaster] = useState({
    show: true,
    message: '',
    icon: null,
  });

  const showToaster = ({ message, icon = null }) => {
    setToaster({
      show: true,
      message,
      icon,
    });
  };

  const hideToaster = () => {
    setToaster({
      show: false,
    });
  };

  return (
    <ToasterContext.Provider value={{ toaster, showToaster, hideToaster }}>
      {children}
      {toaster.show && toaster.message && (
        <Toaster
          open={toaster.show}
          onClose={hideToaster}
          message={toaster.message}
          icon={toaster.icon}
        />
      )}
    </ToasterContext.Provider>
  );
};

export { ToasterContextProvider, ToasterContext };
