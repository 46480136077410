import { createTheme } from '@material-ui/core/styles';

const MEDIUM_BLUE = '#066FF9';
const LIGHT_BLUE = '#5398FF';
const DARK_BLUE = '#1963F1';
const MEDIUM_GREY = '#8C99C8';
const LIGHT_GREY = '#D9E4FE';
const EXTRA_LIGHT_GREY = '#F0F2FA';
const DARK_GREY = '#8A8A98';
const EXTRA_DARK_GREY = '#454550';
const WHITE = '#ffffff';
const BLACK = '#000000';
const LIGHT_PINK = '#FF627E';
const LIGHT_GREEN = '#81C083';
const RED = '#FF627E';
const DARK_RED = '#D93B57';
const LIGHT_RED = '#FF7C94';
const RED_BACKGROUND = '#FFF3F5';

const CustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Hind Siliguri', sans-serif",
    altFontFamily: "'Nunito Sans', sans-serif",
  },
  palette: {
    // custom
    mediumBlue: {
      main: MEDIUM_BLUE,
    },
    lightBlue: {
      main: LIGHT_BLUE,
    },
    darkBlue: {
      main: DARK_BLUE,
    },
    mediumGrey: {
      main: MEDIUM_GREY,
    },
    lightGrey: {
      main: LIGHT_GREY,
    },
    extraLightGrey: {
      main: EXTRA_LIGHT_GREY,
    },
    darkGrey: {
      main: DARK_GREY,
    },
    extraDarkGrey: {
      main: EXTRA_DARK_GREY,
    },
    white: {
      main: WHITE,
    },
    black: {
      main: BLACK,
    },
    lightPink: {
      main: LIGHT_PINK,
    },
    lightGreen: {
      main: LIGHT_GREEN,
    },
    red: {
      main: RED,
    },
    darkRed: {
      main: DARK_RED,
    },
    lightRed: {
      main: LIGHT_RED,
    },
    redBackground: {
      main: RED_BACKGROUND,
    },
    // default
    primary: {
      main: MEDIUM_BLUE,
    },
    error: {
      main: LIGHT_PINK,
    },
    success: {
      main: LIGHT_GREEN,
    },
    gradient: {
      pro: 'linear-gradient(88.42deg, #FF627E -15.7%, #FCCF8C 159.48%)',
      standard: 'linear-gradient(90deg, #738CE2 0%, #3DC0D2 100%)',
      trial:
        'linear-gradient(88.42deg, #FF627E -15.7%, #FF8C7B 42.49%, #FF9D79 95.33%, #FFC876 159.48%);',
      basic:
        'linear-gradient(84.38deg, #5C9DE9 -16%, #C08BFF 45.1%, #AB8BFF 107.5%)',
    },
  },
  props: {
    MuiButton: {
      disableRipple: true,
    },
  },
});

CustomTheme.overrides = {
  MuiMenu: {
    paper: {
      boxShadow:
        '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 50px 60px -19px rgba(14, 26, 68, 0.05)',
      borderRadius: 2,
    },
  },
  MuiTypography: {
    root: {
      textDecoration: 'none',
    },
  },
  MuiTableCell: {
    root: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: '0.01em',
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    text: {
      color: CustomTheme.palette.primary.dark,
      backgroundColor: 'transparent',
      textDecoration: 'none',
      fontSize: 16,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.lightBlue.main,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.darkBlue.main,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.mediumGrey.main,
      },
    },
    textPrimary: {
      backgroundColor: 'transparent',
      textDecoration: 'none',
      fontSize: 16,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.lightBlue.main,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.darkBlue.main,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: CustomTheme.palette.mediumGrey.main,
      },
    },
    contained: {
      boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
      '&:hover': {
        boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
      },
      '&:active': {
        boxShadow: '0px 10px 24px -8px rgba(10, 36, 128, 0.17)',
      },
      '&:disabled': {
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      backgroundColor: MEDIUM_BLUE,
      '&:hover': {
        backgroundColor: LIGHT_BLUE,
      },
      '&:active': {
        backgroundColor: DARK_BLUE,
      },
      '&:disabled': {
        backgroundColor: LIGHT_GREY,
        boxShadow: 'none',
      },
    },
  },
  MuiDivider: {
    root: {
      marginBottom: 16,
      backgroundColor: LIGHT_GREY,
    },
  },
  MuiCssBaseline: {
    '@global': {
      '*': {
        letterSpacing: '0.02em',
      },
      body: {
        color: BLACK,
      },
      '.display-1': {
        fontWeight: 600,
        fontSize: 48,
        lineHeight: '56px',
      },
      '.display-2': {
        fontWeight: 600,
        fontSize: 40,
        lineHeight: '48px',
      },
      '.display-3': {
        fontWeight: 600,
        fontSize: 32,
        lineHeight: '40px',
      },
      '.h1': {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '32px',
        letterSpacing: '0.01em',
      },
      '.h2': {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '26px',
        letterSpacing: '0.01em',
      },
      '.h3': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '26px',
      },
      '.h3-sb': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '26px',
      },
      '.h4': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: '0.05em',
      },
      '.p1': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.01em',
        fontFamily: CustomTheme.typography.altFontFamily,
      },
      'p1 a': {
        color: CustomTheme.palette.primary.main,
      },
      '.p1-sb': {
        // sb - semi-bold
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.01em',
        fontFamily: CustomTheme.typography.altFontFamily,
      },
      '.p2': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        fontFamily: CustomTheme.typography.altFontFamily,
      },
      '.p3': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '20px',
        fontFamily: CustomTheme.typography.altFontFamily,
      },
      '.box-shadow-1': {
        boxShadow: '0px 8px 24px rgba(77, 85, 125, 0.08)',
      },
      '.box-shadow-2': {
        boxShadow: ' 0px 50px 60px -19px rgba(2, 4, 74, 0.03)',
      },
      '.medium-blue': {
        color: MEDIUM_BLUE,
      },
      '.bg-medium-blue': {
        backgroundColor: MEDIUM_BLUE,
      },
      '.light-blue': {
        color: LIGHT_BLUE,
      },
      '.bg-light-blue': {
        backgroundColor: LIGHT_BLUE,
      },
      '.dark-blue': {
        color: DARK_BLUE,
      },
      '.bg-dark-blue': {
        backgroundColor: DARK_BLUE,
      },
      '.medium-grey': {
        color: MEDIUM_GREY,
      },
      '.bg-medium-grey': {
        backgroundColor: MEDIUM_GREY,
      },
      '.light-grey': {
        color: LIGHT_GREY,
      },
      '.bg-light-grey': {
        backgroundColor: LIGHT_GREY,
      },
      '.extra-light-grey': {
        color: EXTRA_LIGHT_GREY,
      },
      '.bg-extra-light-grey': {
        backgroundColor: EXTRA_LIGHT_GREY,
      },
      '.dark-grey': {
        color: DARK_GREY,
      },
      '.bg-dark-grey': {
        backgroundColor: DARK_GREY,
      },
      '.extra-dark-grey': {
        color: EXTRA_DARK_GREY,
      },
      '.bg-extra-dark-grey': {
        backgroundColor: EXTRA_DARK_GREY,
      },
      '.white': {
        color: WHITE,
      },
      '.bg-white': {
        backgroundColor: WHITE,
      },
      '.black': {
        color: BLACK,
      },
      '.red': {
        color: RED,
      },
      '.bg-black': {
        backgroundColor: BLACK,
      },
      '.light-pink': {
        color: LIGHT_PINK,
      },
      '.bg-light-pink': {
        backgroundColor: LIGHT_PINK,
      },
      '.light-green': {
        color: LIGHT_GREEN,
      },
      '.bg-light-green': {
        backgroundColor: LIGHT_GREEN,
      },
      '.center': {
        textAlign: 'center',
      },
    },
  },
};

export default CustomTheme;
