import { useQuery } from 'react-query';
import { db } from '@config/firebase';

export const fetchScanData = async (scanId) => {
  try {
    const scanRef = db.collection('scans').doc(scanId);
    const scanData = await scanRef.get();
    const scan = scanData.data();
    if (scan?.pipelineStatus === 'APPROVED') return scan;
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const useScanData = (scanId) =>
  useQuery(['scan', scanId], () => fetchScanData(scanId), {
    enabled: !!scanId,
    refetchOnWindowFocus: false,
  });
