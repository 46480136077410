import React, { Suspense, useRef, useState } from 'react';
import { useUser } from '@hooks/useUser';
import { ProfilePic } from './ProfilePic';
import { IconButton } from '../IconButton';

const DropdownMenu = React.lazy(() => import('./Dropdown'));

const ProfileDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const { data: user } = useUser();
  const { profilePicUrl } = { ...user };

  const handleClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        buttonRef={anchorRef}
        onClick={handleClick}
        testid="profile-dropdown"
      >
        <ProfilePic src={profilePicUrl} />
      </IconButton>
      <Suspense fallback={<div>Loading...</div>}>
        <DropdownMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} />
      </Suspense>
    </>
  );
};

export default ProfileDropdown;
