import React, { useContext, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useAuthContext } from '@hooks/useAuthContext';
import { firebaseAuthErrorsMap } from '@utils/firebaseAuthErrorsMap';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from '@lib/Loader';
import { StickyButtonContainer } from '@components/ui/StickyButtonContainer';
import { Select } from '@src/lib/Select';
import { withFormContext } from './withFormContext';
import { Input } from './Input';
import { formContext, FormError } from './Form';

const options = [
  {
    value: 'personal',
    label: 'Personal',
    id: 'option1',
    note: 'To scan and share my own measurements',
  },
  {
    value: 'business',
    label: 'Business',
    id: 'option2',
    note: "To receive and manage others' measurements",
  },
  { value: 'both', label: 'Both', id: 'option3' },
];

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    width: 500,
    maxWidth: '100%',
    [breakpoints.up('md')]: {
      width: 408,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: 16,
    },
  },
  passwordInput: {
    marginBottom: 8,
  },
  button: {
    width: 148,
    borderRadius: '52px',
    padding: 10,
  },
}));

const Signup = () => {
  const { row, button, container, passwordInput } = useStyles();
  const { isLoading, isReady, signup, user } = useAuthContext();
  const context = useContext(formContext);
  const { setError, handleSubmit, errorMessage } = { ...context };
  const location = useLocation();

  useEffect(() => {
    handleSubmit(() => async (e) => {
      const { firstName, lastName, email, password, orgName, usageReason } =
        e.target.elements;
      try {
        if (password.value?.length < 8) {
          setError({
            name: 'password',
            message: 'Password must be at least 8 characters long',
          });
          return;
        }
        await signup({
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          password: password.value,
          orgName: orgName.value,
          usageReason: usageReason.value,
        });
      } catch (error) {
        if (error.code || error.message) {
          if (firebaseAuthErrorsMap[error.code]) {
            setError(firebaseAuthErrorsMap[error.code]);
          } else if (firebaseAuthErrorsMap[error.message]) {
            setError(firebaseAuthErrorsMap[error.message]);
          } else {
            setError({
              name: 'email',
              message: 'Something went wrong',
            });
          }
        } else {
          setError({
            name: 'email',
            message: 'Something went wrong',
          });
        }
      }
    });
  }, []);

  if (user && isReady) {
    const previousPath = location.state?.from ? location.state.from : '/';
    return <Redirect to={previousPath} />;
  }

  return (
    <>
      <FormError errorMessage={errorMessage} />
      <div className={container}>
        <div className={row}>
          <Input
            labelText="First Name"
            name="firstName"
            required
            type="text"
            disabled={isLoading}
          />
          <Input
            labelText="Last Name"
            name="lastName"
            required
            type="text"
            disabled={isLoading}
          />
        </div>
        <Input
          labelText="Organization"
          name="orgName"
          type="text"
          disabled={isLoading}
        />
        <Input
          labelText="Email"
          name="email"
          required
          type="email"
          disabled={isLoading}
        />
        <Input
          labelText="Password"
          name="password"
          required
          type="password"
          disabled={isLoading}
          classes={{ root: passwordInput }}
        />
        <Select
          options={options}
          disabled={isLoading}
          placeholder="Select reason"
          defualtValue=""
          label="How would you like to use TrueToForm"
          required
          name="usageReason"
        />
        <StickyButtonContainer parentPaddingX={24}>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={button}
              type="submit"
            >
              Sign up
            </Button>
          )}
        </StickyButtonContainer>
      </div>
    </>
  );
};

export default withFormContext(Signup);
