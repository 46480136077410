import { useEffect } from 'react';
import {
  Paper,
  Typography,
  makeStyles,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Logo } from '@src/img/logo.svg';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: palette.extraLightGrey.main,
    paddingBottom: 24,
    paddingTop: 62,
    [breakpoints.up('sm')]: {
      paddingTop: 76,
    },
  },
  paper: {
    height: 76,
    padding: '0 20.41%',
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: '0 16px',
      height: 62,
    },
    position: 'fixed',
    top: 0,
    width: '100%',
    '& svg, & a': {
      [breakpoints.down('sm')]: {
        width: 40,
        height: 30,
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 24,
    padding: '0 16px',
    [breakpoints.up('sm')]: {
      marginTop: 40,
    },
  },
  center: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 9,
  },
  link: {
    textDecoration: 'none',
    color: palette.mediumBlue.main,
    fontWeight: 600,
  },
}));

const LayoutTitleText = () => {
  const { title, link, center } = useStyles();
  const { pathname } = useLocation();
  const isMobile = !useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));

  const verifyYourEmail = pathname === '/verifyEmail';
  if (verifyYourEmail) return null;

  const isSignUp = pathname === '/signup';

  return (
    <>
      <Typography
        variant="h1"
        component="h1"
        className={clsx(title, center, 'h1 black')}
      >
        {isSignUp ? `Create Your Account` : 'Welcome back'}
      </Typography>
      <Typography
        variant="h1"
        component="h1"
        className={clsx(center, 'p1 dark-grey')}
      >
        {isSignUp ? 'Already have an account?' : 'Don’t have an account?'}{' '}
        {isMobile && <br />}
        <Link
          to={isSignUp ? '/signin' : '/signup'}
          className={clsx('p1', link)}
        >
          {isSignUp ? 'Log in' : 'Sign up and try it free'}
        </Link>
      </Typography>
    </>
  );
};

const LayoutBottomText = () => {
  const { center, link } = useStyles();
  const { pathname } = useLocation();
  if (pathname === '/verifyEmail') return null;
  return (
    <>
      <Button
        variant="text"
        color="primary"
        style={{ marginBottom: '14px', padding: 0 }}
        component="a"
        href="https://www.truetoform.fit/"
        target="_blank"
      >
        Back to www.TrueToForm.fit
      </Button>
      <Typography
        variant="h4"
        component="p"
        className={clsx(center, 'p2 dark-grey')}
      >
        By using TrueToForm, you agree to our <br />
        <a
          className={link}
          href="https://www.truetoform.fit/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>{' '}
        &{' '}
        <a
          className={link}
          href="https://www.truetoform.fit/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Typography>
    </>
  );
};

const authRoutes = ['/signup', '/signin', '/verifyEmail'];

export const Layout = ({ children }) => {
  const { container, paper, content } = useStyles();
  const { pathname } = useLocation();

  const notAuthRoute = !authRoutes.includes(pathname);

  useEffect(() => {
    if (notAuthRoute) return;
    // this to support the background color takes the whole screen
    // for Safari, as Safari doesn't support height: 100vh
    document.getElementById('root').style.backgroundColor = '#F0F2FA';
    return () => {
      document.getElementById('root').style.backgroundColor = '#fff';
    };
  }, []);

  if (notAuthRoute) {
    return <>{children}</>;
  }

  return (
    <div className={container}>
      <Paper className={clsx(paper, 'box-shadow-1')}>
        <a href="https://www.truetoform.fit/" target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </Paper>
      <div className={content}>
        <LayoutTitleText />
        {children}
        <LayoutBottomText />
      </div>
    </div>
  );
};
