import React, { useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { ReactComponent as ProfileIcon } from '@assets/icons/ic_user.svg';
import profileIconMobile from '@assets/icons/ic_account_mobile.svg';
import { ReactComponent as EditIcon } from '@assets/icons/ic_edit.svg';
import clsx from 'clsx';
import { firebase, db } from '@config/firebase';
import { Loader } from '@lib/Loader';
import { useModalContext } from '@hooks/useModalContext';
import { useUser } from '@hooks/useUser';
import { useQueryClient } from 'react-query';

// lazy components
const ImageCropModal = React.lazy(() =>
  import('@components/Modal/ImageCropModal')
);

const useStyles = makeStyles(() => ({
  container: { position: 'relative', cursor: 'pointer' },
  avatar: {
    width: (props) => (props.isEditable ? 66 : 43.2),
    height: (props) => (props.isEditable ? 66 : 43.2),
    borderRadius: '50%',
    '& rect': {
      fill: 'transparent',
      width: 'inherit',
      height: 'inherit',
    },
    '& circle,& line,& path': {
      stroke: '#066FF9',
    },
  },
  editIcon: {
    position: 'absolute',
    top: 66,
    left: 66,
    transform: 'translate(-100%, -100%)',
  },
  input: {
    width: '100%',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
}));

export const ProfilePic = ({ src, alt, isEditable, className }) => {
  const { container, avatar, editIcon, input } = useStyles({ isEditable });
  const [isLoading, setIsLoading] = useState(false);
  const { openModal } = useModalContext();
  const { data: user } = useUser();
  const { clientId } = { ...user };
  const queryClient = useQueryClient();
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleUploadeImage = async (file) => {
    setIsLoading(true);
    try {
      const uploadTask = await firebase
        .storage()
        .ref(`/org_profiles/${clientId}/profilePic`)
        .put(file);
      const downloadURL = await uploadTask.ref.getDownloadURL();

      const orgDocs = await db
        .collection('clientIds')
        .where('clientId', '==', clientId)
        .get();

      for (const doc of orgDocs.docs) {
        await doc.ref.update({ profilePicUrl: downloadURL });
      }
      await queryClient.invalidateQueries('user');
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = URL.createObjectURL(file);
      openModal({
        component: ImageCropModal,
        props: {
          src: img,
          onApprove: handleUploadeImage,
          onCancel: () => {
            // if the user closed the modal then opened again and choosed the
            // same image, we need to reset the input
            // otherwise, this onChnage event won't be triggered again
            e.target.value = null;
          },
        },
        isPaper: false,
      });
    }
  };

  return (
    <div className={container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {src ? (
            <img
              src={src}
              alt={alt}
              className={clsx(avatar, className)}
              data-testid="profile-picture"
            />
          ) : (
            <>
              <ProfileIcon
                className={clsx(avatar, className)}
                data-testid="profile-picture-icon"
              />
              {/* {isNotMobile ? (
                <ProfileIcon
                  className={clsx(avatar, className)}
                  data-testid="profile-picture-icon"
                />
              ) : (
                <img
                  className={clsx(className)}
                  data-testid="profile-picture-icon"
                  src={profileIconMobile}
                  alt="profile icon"
                  width={24}
                  height={24}
                />
              )} */}
            </>
          )}
          {isEditable && (
            <>
              <EditIcon className={editIcon} />
              <input
                type="file"
                accept="image/*"
                className={input}
                onChange={handleSelectImage}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
