/* eslint-disable react/display-name */
import { Layout } from './Layout';
import { Form } from './Form';

export const withFormContext =
  (Component, title = '', isModal = false) =>
  (props) =>
    (
      <Layout>
        <Form title={title} isModal={isModal}>
          <Component {...props} />
        </Form>
      </Layout>
    );
