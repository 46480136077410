import { useContext } from 'react';
import { BillingInfoContext } from '@context/BillingInfoContext';

export function useBillingInfoContext() {
  const context = useContext(BillingInfoContext);
  if (context === undefined) {
    throw new Error(
      'useBillingInfoContext must be used within a BillingInfoContextProvider'
    );
  }
  return context;
}
