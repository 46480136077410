export function formatScanData({ scans = [], metrics = [], scanId }) {
  const scan = scans.find((scanDoc) => scanDoc?.id === scanId);
  if (!scan) return null;
  const scanMetrics = metrics.find((metric) => metric?.id === scanId);
  scan.name = scan?.name || 'No Name';
  scan.email = scan?.email || 'No Email';

  if (scanMetrics) {
    scan.bust = scanMetrics.bust * 39.37;
    scan.waist = scanMetrics.waist * 39.37;
    scan.hips = scanMetrics.hip * 39.37;
    return {
      ...scan,
    };
  }

  return {
    bust: '',
    waist: '',
    hips: '',
    ...scan,
  };
}
