import { useEffect, useState } from 'react';
import { Modal, Paper, makeStyles } from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@components/ErrorFallback';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    margin: theme.spacing(3, 2),
    maxHeight: `calc(100vh - ${theme.spacing(3) * 2}px)`,
    // for the direct child of paper (which is the content) make it scrollable
    '& > *:first-child': {
      maxHeight: 'inherit', // this is to make the content scrollable
      overflowY: 'scroll',
    },
  },
  notPaperContainer: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(3, 2),
    maxHeight: `calc(100vh - ${theme.spacing(3) * 2}px)`,
    '& > *:first-child': {
      maxHeight: 'inherit',
      overflowY: 'scroll',
    },
  },
  modal: {
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(2 4 74 / 76%)',
    outline: 0,
    '& div:focus': {
      outline: 'none',
    },
    zIndex: '9999999 !important',
  },
}));

const ModalLayout = ({ children, open, onClose, isPaper = true }) => {
  const classes = useStyles();
  const [maxHeight, setMaxHeight] = useState(0);
  // temp fix for kicking a re-render to fix iOS Safari issue
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!open) return;
    setTimeout(() => {
      setKey((prevKey) => prevKey + 1);
    }, 100);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    function setMaxHeightState() {
      const vh = window.visualViewport.height;
      setMaxHeight(vh);
    }
    setMaxHeightState();
    window.addEventListener('resize', setMaxHeightState);
    return () => {
      window.removeEventListener('resize', setMaxHeightState);
    };
  }, [open, children, key]);

  useEffect(() => {
    if (!open) return;
    const root = document.getElementById('root');
    if (open) {
      root.style.maxHeight = `${maxHeight}px`;
      root.style.overflow = 'hidden';
    } else {
      root.style.maxHeight = 'auto';
      root.style.overflow = 'auto';
    }
    // on unmount make #root maxHeigh auto
    return () => {
      root.style.maxHeight = 'auto';
      root.style.overflow = 'auto';
    };
  }, [open, maxHeight, children, key]);

  useEffect(() => {
    if (!open) return;
    // set the height of layout to vh (Support Safari)
    let layout = null;
    const modal = document.getElementById('modal-container');
    if (modal) {
      modal.style.maxHeight = `${maxHeight}px`;
      // get Modal backdrop first child of modal
      const backdrop = modal.children[0];
      layout = document.getElementById('modal-layout');
      if (backdrop) {
        backdrop.style.maxHeight = `${maxHeight}px`;
      }
    }
    if (layout) {
      layout.style.maxHeight = `${maxHeight - 48}px`;
      layout.style.overflowX = 'hidden';
    }
  }, [maxHeight, children, key]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      id="modal-container"
    >
      {isPaper ? (
        <Paper
          className={classes.paper}
          data-testid="modal-layout"
          tabIndex={-1}
          id="modal-layout"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(err) => {
              console.log({ err });
            }}
          >
            {children}
          </ErrorBoundary>
        </Paper>
      ) : (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(err) => {
            console.log({ err });
          }}
        >
          <div
            data-testid="modal-layout"
            className={classes.notPaperContainer}
            tabIndex={-1}
            id="modal-layout"
          >
            {children}
          </div>
        </ErrorBoundary>
      )}
    </Modal>
  );
};

export { ModalLayout };
