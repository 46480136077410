import { createContext, useState, useRef, useEffect, useCallback } from 'react';
import moment from 'moment';
import { db, auth } from '@config/firebase';
import { useQueryClient } from 'react-query';

const BillingInfoContext = createContext();
BillingInfoContext.displayName = 'BillingInfoContext';

const BillingInfoContextProvider = (props) => {
  const [monthlyCredits, setMonthlyCredits] = useState(0);
  const [totalThisMonth, setTotalThisMonth] = useState(0);
  const [endOfPeriodDate, setEndOfPeriodDate] = useState('');
  const [formattedInvoiceDate, setFormattedInvoiceDate] = useState('');
  const [lastPaymentDate, setLastPaymentDate] = useState('');
  const [overUsageAvatars, setOverUsageAvatars] = useState(0);
  const [overUsagePrice, setOverUsagePrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subscriptionFee, setSubscriptionFee] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [isCustomPlan, setIsCustomPlan] = useState(false);
  const [billingInfoDocData, setBillingInfoDocData] = useState({});
  // used inside the locked Modal
  const [failedPaymentFormattedDate, setFailedPaymentFormattedDate] =
    useState('');
  // optimistically update the UI with the new data
  const [optimisticData, setOptimisticData] = useState({});
  const unsubscribeInvoice = useRef();
  const previousAccountType = useRef();
  const [currentAccountType, setCurrentAccountType] = useState(
    previousAccountType.current
  );
  const queryClient = useQueryClient();

  const fetchData = useCallback(async (optimisticDataParam = {}) => {
    unsubscribeInvoice.current?.(); // unsubscribe from the previous listener
    const { uid } = { ...auth.currentUser };
    const accountRef = db.collection('accounts').doc(uid);
    const invoicesRef = accountRef.collection('invoices');
    const billingInfoRef = invoicesRef.doc('billingInfo');
    const billingInfo = await billingInfoRef.get();
    // optimistic update if optimisticDataParam is an object
    if (typeof optimisticDataParam === 'object') {
      setOptimisticData(() => optimisticDataParam);
    }
    if (billingInfo.exists) {
      const { billingDate, isCustomPlan: userCustomPlan } = {
        ...billingInfo.data(),
      };
      setBillingInfoDocData(() => billingInfo.data());
      if (billingDate) {
        setIsCustomPlan(Boolean(userCustomPlan));
        // get the number of the current day utc currentDayUTC
        const currentDayUTC = moment.utc().date();
        // if day {billingDate} is in the same month
        let invoiceDate = moment
          .utc()
          .date(billingDate)
          .startOf('day')
          .toDate();
        // if day {billingDate} is on Next Month
        if (currentDayUTC >= billingDate) {
          invoiceDate = moment
            .utc()
            .add(1, 'month')
            .date(billingDate)
            .startOf('day')
            .toDate();
        }
        const userData = await accountRef.get();
        const { trialEndDate, isTrial } = { ...userData.data() };
        if (isTrial) {
          invoiceDate = trialEndDate?.toDate();
        }
        setEndOfPeriodDate(() => moment(invoiceDate).format('MMM Do'));
        const startOfPeriodDate = moment(invoiceDate)
          .subtract(1, 'month')
          .format('MM-DD-yyy');
        setLastPaymentDate(startOfPeriodDate);
        setFormattedInvoiceDate(moment(invoiceDate).format('MM-DD-yyy'));
        setFailedPaymentFormattedDate(
          `${moment(invoiceDate)
            .subtract(1, 'month')
            .format('MMM Do')} - ${moment(invoiceDate).format('MMM Do, yyy')}`
        );
        const currentInvoiceRef = invoicesRef.doc(
          moment.utc(invoiceDate).format('YYYY-MM-DD')
        );
        unsubscribeInvoice.current = currentInvoiceRef.onSnapshot(
          (currentInvoiceSnapshot) => {
            if (currentInvoiceSnapshot.exists) {
              const {
                usage,
                monthlyCredits: monthlyCreditsOrigin,
                totalAmount: totalAmountOrigin,
                subscriptionFee: subscriptionFeeOrigin,
                unitPrice: unitPriceOrigin,
                linkedAccountType,
              } = {
                ...currentInvoiceSnapshot.data(),
              };
              setTotalThisMonth(usage);
              setTotalAmount(totalAmountOrigin);
              setMonthlyCredits(monthlyCreditsOrigin);
              const overusage = Math.max(usage - monthlyCreditsOrigin, 0);
              setOverUsageAvatars(overusage);
              setOverUsagePrice(overusage * unitPriceOrigin);
              setSubscriptionFee(subscriptionFeeOrigin);
              setUnitPrice(unitPriceOrigin);
              setCurrentAccountType(linkedAccountType);
            }
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    // fetch data if the account type changes
    if (currentAccountType !== previousAccountType.current) {
      fetchData();
      previousAccountType.current = currentAccountType;
      // refetch the query to update the UI
      queryClient.invalidateQueries('user');
      queryClient.invalidateQueries('paymentMethods');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountType]);

  useEffect(() => {
    try {
      fetchData();
      // fetch data on Window Focus
      const onFocus = () => {
        fetchData();
      };
      window.addEventListener('focus', onFocus);
      return () => {
        unsubscribeInvoice.current?.();
        window.removeEventListener('focus', onFocus);
      };
    } catch (e) {
      console.log(e);
    }
  }, []);

  const value = {
    monthlyCredits,
    totalThisMonth,
    endOfPeriodDate,
    formattedInvoiceDate,
    lastPaymentDate,
    overUsageAvatars,
    totalAmount,
    overUsagePrice,
    subscriptionFee,
    unitPrice,
    isCustomPlan,
    failedPaymentFormattedDate,
    fetchData,
    ...(billingInfoDocData || {}),
    // optimistic data should be the last one to be merged and overwrite the other data
    ...(optimisticData || {}),
  };

  return <BillingInfoContext.Provider {...props} value={value} />;
};

export { BillingInfoContext, BillingInfoContextProvider };
