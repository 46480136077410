import { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  styled,
  Typography,
  InputBase,
  MenuItem,
  Select as MuiSelect,
  FormControl,
} from '@material-ui/core';
import { ReactComponent as UpArrowIcon } from '@assets/icons/ic_arrow_up.svg';
import { ReactComponent as DownArrowIcon } from '@assets/icons/ic_arrow_down.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/ic_error.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  container: {
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '12px',
    transform: 'translateY(-50%)',
  },
  menu: {
    borderRadius: '2px',
    border: `1px solid ${theme.palette.lightGrey.main}`,
    boxShadow: 'none',
    padding: 0,
    width: '100%',
    '& ul': {
      padding: '8px 0',
    },
    '& .MuiMenuItem-root.Mui-selected': {
      backgroundColor: 'rgba(83, 152, 255, 0.16)',
    },
    '& .MuiMenuItem-root': {
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(83, 152, 255, 0.08)',
      },
      '&:active': {
        backgroundColor: 'rgba(83, 152, 255, 0.16)',
      },
    },
  },
  menuItem: {
    padding: '10px 12px 10px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    display: 'flex',
    height: 24,
    alignItems: 'center',
    position: 'relative',
    marginTop: 8,
  },
  errorIcon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const SelectBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  borderRadius: '2px',
  border: `1px solid ${theme.palette.lightGrey.main}`,
  '&:before': {
    content: 'none',
  },
  '&:focus': {
    '&:before': {
      content: 'none',
    },
  },
  '& .MuiSelect-root': {
    padding: '10px',
    paddingRight: '44px',
    paddingLeft: '16px',
    margin: ' 0 -1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:focus, &:active': {
      backgroundColor: 'rgba(83, 152, 255, 0.16)',
    },
  },
  '& .MuiInputBase-input': {
    '& .p2.extra-dark-grey': {
      display: 'none',
    },
  },
}));

export const Select = ({
  options,
  onSelect = () => {},
  disabled,
  defualtValue,
  placeholder,
  label: formControlLabel,
  required,
  hasError,
  name,
}) => {
  const {
    icon,
    container,
    menuItem,
    menu,
    wrapper,
    label: labelStyles,
    errorIcon,
  } = useStyles();
  const [selectValue, setSelectValue] = useState(defualtValue);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectValue(defualtValue);
    onSelect(defualtValue);
  }, []);

  const handleChange = (event) => {
    setSelectValue(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <FormControl className={wrapper} required>
      {formControlLabel && (
        <label
          className={clsx('p2', 'dark-grey', labelStyles)}
          htmlFor="select"
        >
          {formControlLabel}
          {required && <span className="red">*</span>}
          {hasError && <ErrorIcon className={errorIcon} />}
        </label>
      )}
      <MuiSelect
        name={name || 'select'}
        placeholder={placeholder}
        value={selectValue}
        onChange={handleChange}
        variant="standard"
        input={<SelectBase />}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={
          isOpen
            ? () => <UpArrowIcon className={icon} />
            : () => <DownArrowIcon className={icon} />
        }
        className={container}
        required={required}
        MenuProps={{
          classes: {
            paper: menu,
          },
          PaperProps: {
            style: {
              width: 'fit-content',
            },
          },
          PopperProps: {
            placement: 'bottom-start',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null, // This prevents the selected item from being used as the anchor element
          disablePortal: true,
        }}
        disabled={disabled}
        data-testid="select"
        displayEmpty={!!placeholder}
      >
        {placeholder && (
          <MenuItem value="" disabled style={{ display: 'none' }}>
            <Typography className="p1" variant="body1">
              {placeholder}
            </Typography>
          </MenuItem>
        )}
        {options.map(({ label, value, note, id }) => (
          <MenuItem key={id} value={value} className={menuItem}>
            <Typography className="p1" variant="body1">
              {label}
            </Typography>
            {note && (
              <Typography className="p2 extra-dark-grey" variant="body1">
                {note}
              </Typography>
            )}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.object.isRequired,
      note: PropTypes.string,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};
