import { memo } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { capitalize } from '@utils/capitalize';
import { useAvatarViewerSelector } from '@context/AvatarViewerContext';
import { BackButton } from './BackButton';

export const ScanDetailsText = memo(() => {
  const { scanData, units, convertToCm } = useAvatarViewerSelector((state) => ({
    scanData: state.scanDoc,
    units: state.units,
    convertToCm: state.convertToCm,
  }));
  const {
    name: scannerName,
    timestamp: scanDate,
    gender,
    height,
    weight,
    age,
  } = scanData || {};
  const isMobile = useMediaQuery('(max-width: 1200px)');
  return (
    <Box>
      <Box display="flex" alignItems="center" gridGap="18px">
        {isMobile && <BackButton />}
        <Typography variant="h1" className="h1">
          {capitalize(scannerName) || 'No Name'}
        </Typography>
      </Box>
      <Typography variant="body1" className="p1">
        {moment(scanDate * 1000).format('MMMM D yyyy h:mm a')} • {gender}{' '}
        {isMobile && <br />}
        {height &&
          `${!isMobile ? '• ' : ''}Height ${
            units === 'in' ? `${height} in` : `${convertToCm(height)} cm`
          }`}{' '}
        {weight && `• Weight ${weight} lb`} {age && `• Age ${age}`}
      </Typography>
    </Box>
  );
});
ScanDetailsText.displayName = 'ScanDetailsText';
