import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Search } from './Search';
import SortDropdown from './SortDropdown';
import ProfileDropdown from './ProfileDropdown';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  divider: {
    height: 24,
    width: 1,
    backgroundColor: theme.palette.lightGrey.main,
  },
}));

const ProfileMenu = ({
  getSortType,
  getSearchValue,
  reset,
  adminPortal = false,
  sortType,
}) => {
  const { container, divider } = useStyles();
  const location = useLocation();
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const shouldDisplayFiltering =
    !location.pathname.includes('dashboard/pending');

  return (
    <div className={container}>
      {shouldDisplayFiltering && isNotMobile && (
        <>
          <Search getSearchValue={getSearchValue} reset={reset} />
          <SortDropdown
            getSortType={getSortType}
            adminPortal={adminPortal}
            sortType={sortType}
          />
          <div className={divider} />
        </>
      )}
      <ProfileDropdown />
    </div>
  );
};

export default ProfileMenu;
