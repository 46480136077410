import React, { Suspense, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ReactComponent as Logo } from '@src/img/logo.svg';
import { useUser } from '@hooks/useUser';
import { useScans } from '@hooks/useScans';
import TopBar from '@components/TopBar';
import { NoSearchResults } from '@src/layouts/NoSearchResults';
import moment from 'moment';
import { formatSearchScans } from '@utils/formatSearchScans';
import { sortArr } from '@src/utils/sortArr';
import EmptyAvatarPage from './EmptyAvatarPage';
// lazy components
const Dashboard = React.lazy(() => import('@components/AvatarsDashboard'));

export const AvatarDashboard = () => {
  const {
    data: user,
    isSuccess: userSuccess,
    isLoading: userLoading,
  } = useUser();
  const { isLoading, scans } = useScans(user?.isAdmin ? 'ALL' : user?.clientId);

  const loading = userLoading || isLoading;
  const [sortType, setSortType] = useState('desc');
  const [tempScans, setTempScans] = useState(scans);
  const [searchValue, setSearchValue] = useState(null);
  const [scansResult, setScansResult] = useState(tempScans);

  const reset = () => {
    setTempScans(formatSearchScans(user?.isAdmin, scans));
    setSortType('desc');
  };

  useEffect(() => {
    if (searchValue) {
      const filteredScansResult = tempScans?.filter((scan) => {
        const date = moment(scan?.timestamp * 1000).format('MM/DD/YYYY');
        if (!user?.isAdmin) {
          return (
            scan.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            date?.toLowerCase().includes(searchValue.toLowerCase())
          );
        }
        return (
          scan.shareStatus?.toLowerCase().includes(searchValue.toLowerCase()) ||
          scan.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          scan.gender?.toLowerCase().includes(searchValue) ||
          scan.emaiL?.toLowerCase().includes(searchValue.toLowerCase()) ||
          scan?.clientId?.toLowerCase().includes(searchValue.toLowerCase()) ||
          scan?.userId?.toLowerCase().includes(searchValue.toLowerCase()) ||
          scan?.id?.toLowerCase().includes(searchValue.toLowerCase()) ||
          date?.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setTempScans([...filteredScansResult]);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    const filteredScans =
      tempScans?.length && tempScans.length !== scans.length
        ? tempScans.map(({ id }) => scans?.find((scan) => scan.id === id))
        : scans;
    const userScans = formatSearchScans(user?.isAdmin, filteredScans);
    const sortedUserScans = sortArr(userScans, sortType);
    setTempScans([...sortedUserScans]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scans]);

  useEffect(() => {
    setScansResult(tempScans);
  }, [tempScans]);

  useEffect(() => {
    if (!isLoading && scans && sortType === 'desc') {
      const formattedScans = formatSearchScans(user?.isAdmin, scans);
      const sortedScans = sortArr(formattedScans, sortType);
      setTempScans([...sortedScans]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scans]);

  useEffect(() => {
    const sortedScans = sortArr(tempScans, sortType);
    setTempScans([...sortedScans]);
  }, [sortType]);

  if (loading)
    return (
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Logo />
      </Grid>
    );

  const hasNoScans =
    userSuccess && (scans?.length === 0 || !scans) && !user.isAdmin;

  const emptyResults = scansResult?.length === 0;

  return (
    <Suspense
      fallback={
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100vh' }}
        >
          <Logo />
        </Grid>
      }
    >
      <Box height="100%">
        <TopBar
          title="Avatars"
          getSortType={setSortType}
          getSearchValue={setSearchValue}
          reset={reset}
          sortType={sortType}
        />
        {hasNoScans ? (
          <EmptyAvatarPage />
        ) : emptyResults ? (
          <NoSearchResults text="No matches in “Avatars”" />
        ) : (
          <Dashboard user={user} scansResult={scansResult} />
        )}
      </Box>
    </Suspense>
  );
};
