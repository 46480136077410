import React, { memo, useMemo } from 'react';
import {
  Box,
  Button,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { OpenModalButton } from '@components/Modal/OpenModalButton';
import { useAvatarViewerSelector } from '@context/AvatarViewerContext';
import { useUser } from '@hooks/useUser';
import { ViewerTableRow } from './ViewerTableRow';
import { ExportRow } from './ExportRow';

const ChoosePlanModal = React.lazy(() =>
  import('@components/Modal/ChoosePlan')
);

const FullMeasurementsContent = () => {
  const { data: user } = useUser();
  return (
    <>
      <TableCell
        colSpan={9}
        style={{
          padding: '0',
        }}
      >
        <ExportRow />
      </TableCell>
      <Box
        style={{
          background: '#EEF0F9',
          padding: '6px 13px 9px 13px',
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="500px"
        component={TableRow}
      >
        <TableCell colSpan={6}>
          <Typography variant="h2" className="h2">
            Full Measurements
          </Typography>
        </TableCell>
        <TableCell colSpan={3} align="right">
          <OpenModalButton component={ChoosePlanModal} isPaper={false}>
            <Button variant="text" color="primary" disabled={!user}>
              Start plan to view
            </Button>
          </OpenModalButton>
        </TableCell>
      </Box>
    </>
  );
};

export const MetricsRows = memo(() => {
  const {
    circOrder,
    lengthOrder,
    circData,
    lengthData,
    units,
    showCirc,
    hasLockedGirthMetrics,
    hasLockedLengthMetrics,
    lockedGirthMetrics,
    lockedLengthMetrics,
    unlockedGirthMetrics,
    unlockedLengthMetrics,
    hadPlanDowngrade,
    toggleLine,
  } = useAvatarViewerSelector((state) => ({
    circOrder: state.circOrder,
    lengthOrder: state.lengthOrder,
    circData: state.circData,
    lengthData: state.lengthData,
    units: state.units,
    showCirc: state.showCirc,
    hasLockedGirthMetrics: state.hasLockedGirthMetrics,
    hasLockedLengthMetrics: state.hasLockedLengthMetrics,
    lockedGirthMetrics: state.lockedGirthMetrics,
    lockedLengthMetrics: state.lockedLengthMetrics,
    unlockedGirthMetrics: state.unlockedGirthMetrics,
    unlockedLengthMetrics: state.unlockedLengthMetrics,
    hadPlanDowngrade: state.hadPlanDowngrade,
    toggleLine: state.toggleLine,
  }));
  const allUnlockedMetrics = [
    ...unlockedGirthMetrics,
    ...unlockedLengthMetrics,
  ];

  const isValidMetric = (label) =>
    !!circData[label]?.value || !!lengthData[label]?.value;

  const CircMetrics = useMemo(() => {
    if (!hasLockedGirthMetrics)
      return circOrder.map(
        (label, i) =>
          !!circData[label]?.value && (
            <ViewerTableRow
              key={label.toString()}
              data={circData[label]}
              units={units}
              id={label.toString()}
              index={i}
              toggleLine={toggleLine}
              isCirc
            />
          )
      );

    return (
      <>
        {allUnlockedMetrics.map(
          (label, i) =>
            isValidMetric(label) && (
              <ViewerTableRow
                key={label.toString()}
                data={circData[label] || lengthData[label]}
                units={units}
                id={label.toString()}
                index={i}
                toggleLine={toggleLine}
                isCirc
                shouldAnimate={false}
              />
            )
        )}
        <FullMeasurementsContent />
        {lockedGirthMetrics.map(
          (label, i) =>
            !!circData[label]?.value && (
              <ViewerTableRow
                key={label.toString()}
                data={circData[label]}
                units={units}
                id={label.toString()}
                index={i}
                toggleLine={toggleLine}
                isCirc
              />
            )
        )}
      </>
    );
  }, [
    circData,
    circOrder,
    hadPlanDowngrade,
    hasLockedGirthMetrics,
    lockedGirthMetrics,
    toggleLine,
    units,
    unlockedGirthMetrics,
  ]);

  const LengthMetrics = useMemo(() => {
    if (!hasLockedLengthMetrics)
      return allUnlockedMetrics.map(
        (label, i) =>
          !!lengthData[label]?.value && (
            <ViewerTableRow
              key={label.toString()}
              data={lengthData[label]}
              units={units}
              id={label.toString()}
              index={i}
              toggleLine={toggleLine}
              isCirc={false}
            />
          )
      );

    return (
      <>
        {allUnlockedMetrics.map(
          (label, i) =>
            isValidMetric(label) && (
              <ViewerTableRow
                key={label.toString()}
                data={lengthData[label] || circData[label]}
                units={units}
                id={label.toString()}
                index={i}
                toggleLine={toggleLine}
                isCirc={false}
                shouldAnimate={false}
              />
            )
        )}
        <FullMeasurementsContent />
        {lockedLengthMetrics.map(
          (label, i) =>
            !!lengthData[label]?.value && (
              <ViewerTableRow
                key={label.toString()}
                data={lengthData[label]}
                units={units}
                id={label.toString()}
                index={i}
                toggleLine={toggleLine}
                isCirc={false}
              />
            )
        )}
      </>
    );
  }, [
    hadPlanDowngrade,
    hasLockedLengthMetrics,
    lengthData,
    lengthOrder,
    lockedLengthMetrics,
    toggleLine,
    units,
    unlockedLengthMetrics,
  ]);

  return <>{showCirc ? CircMetrics : LengthMetrics}</>;
});
MetricsRows.displayName = 'MetricsRows';
