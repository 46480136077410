/* eslint-disable guard-for-in */
import React, {
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useImmer } from 'use-immer';
import { useRouteMatch } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { BlackButton } from '@components/AvatarViewer/BlackButton';
import { ScanDetailsText } from '@components/AvatarViewer/ScanDetailsText';
import { Line2, LineMaterial, LineGeometry } from '@components/lines';
import { InteractiveSlider } from '@components/AvatarViewer/InteractiveSlider';
import { AvatarViewerProvider } from '@context/AvatarViewerContext';
import { BackButton } from '@components/AvatarViewer/BackButton';
import { MetricsTable } from '@components/AvatarViewer/MetricsTable';
import { analytics, auth, db, storage } from '@config/firebase';
import { MeasurementContext } from '@context/MeasurementContext';
import { withAuthHoc } from '@components/AvatarViewer/withAuthHoc';
import { useUser } from '@hooks/useUser';
import { SignupBanner } from '@components/AvatarViewer/SignupBanner';
import { ReactComponent as Logo } from '../img/logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#8e919a', // the same as the scene background
  },
  scanDetails: {
    position: 'absolute',
    top: 14,
    left: 14,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  resetButton: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      left: '50%',
      marginLeft: -56,
    },
  },
  tableCell: {
    fontSize: 16,
    fontWeight: 500,
  },
  signupBanner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
  },
}));

const defaultState = {
  circData: {},
  circOrder: [],
  lengthData: {},
  lengthOrder: [],
  loadingPercentage: 0,
  showCirc: true,
  loadingMetrics: true,
  hasLockedGirthMetrics: false,
  hasLockedLengthMetrics: false,
  lockedGirthMetrics: [],
  unlockedGirthMetrics: [],
  lockedLengthMetrics: [],
  unlockedLengthMetrics: [],
  // Defaults
  torsoGirths: [],
  validSliderVersion: false,
  showSlider: false,
  sliderPercent: 100,
  sliderLevel: 0,
  sliderReady: false,
  currentCircum: 0,
  floorLevel: undefined, // undefined | number
  currentHeight: 0,
  min: 0,
  max: 0,
  units: 'in',
  drawnMetrics: [],
};

function getWidth() {
  return window.innerWidth > 1200 ? window.innerWidth * 0.7 : window.innerWidth;
}

const AvatarViewer = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { scanId } = match.params;
  const [objUrl, setObjUrl] = useState('');
  const measurementContext = useContext(MeasurementContext);
  const [state, setState] = useImmer(defaultState);
  const [sliderChangesCount, setSliderChangesCount] = useState(0);
  const [fov, setFov] = useState(() => {
    const width = window.innerWidth;
    return width < 1200 ? 88 : 75;
  });
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [visibleContentHeight, setVisibleContentHeight] = useState(
    isMobile ? 78 : 0
  );
  const [canvasHeight, setCanvasHeight] = useState(() =>
    window.innerWidth < 1200
      ? window.visualViewport.height - visibleContentHeight
      : window.visualViewport.height
  );
  const viewerCanvasRef = useRef(null);
  const scene = useRef(null);
  const camera = useRef(null);
  const renderer = useRef(null);
  const controls = useRef(null);
  const [{ circCheckboxValue, lengthCheckboxValue }, setCheckboxValue] =
    useImmer({
      circCheckboxValue: false,
      lengthCheckboxValue: false,
    });
  const { data: user } = useUser();
  const displaySignupBanner = !user && isMobile && !state.loadingMetrics;
  const [signupBannerHeight, setSignupBannerHeight] = useState(0);
  const signupBannerRef = useRef(null);

  const convertToInches = useCallback((m) => Math.round(m * 3937.01) / 100, []);

  const convertToCm = useCallback(
    (i) => Math.round((i / 39.3701) * 10000) / 100,
    []
  );

  const resetView = useCallback(() => {
    if (!controls.current) return;
    controls.current.reset();
    analytics.logEvent('reset_view', {});
  }, []);

  const updateCanvasDimensions = useCallback(
    (width, height) => {
      // update the container dom element
      const container = document.getElementById('viewer-canvas');
      if (container) {
        container.style.width = `${width}px`;
        container.style.height = `${height}px`;
      }
      if (!renderer.current || !camera.current) return;
      renderer.current.setSize(width, height);
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
      resetView();
    },
    [resetView]
  );

  const handleWindowResize = () => {
    const width = getWidth();
    const windowWidth = window.innerWidth;
    if (!renderer.current || !camera.current) return;
    const FOV_BREAKPOINT = 1200;
    let newHeight = canvasHeight;
    if (windowWidth < FOV_BREAKPOINT) {
      setFov(88);
      const bannerHeight = displaySignupBanner ? signupBannerHeight : 0;
      setCanvasHeight(
        window.visualViewport.height - visibleContentHeight - bannerHeight
      );
      newHeight =
        window.visualViewport.height - visibleContentHeight - bannerHeight;
    } else {
      setFov(75);
      setCanvasHeight(window.visualViewport.height);
      newHeight = window.visualViewport.height;
    }
    updateCanvasDimensions(width, newHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (signupBannerRef.current) {
      setSignupBannerHeight(signupBannerRef.current.clientHeight);
    }
  }, [displaySignupBanner]);

  useEffect(() => {
    handleWindowResize();
  }, [signupBannerHeight]);

  const hasLegScan = () => {
    const { scanDocData } = state;
    return scanDocData?.hasLegScan || scanDocData?.hasFullBodyScan;
  };

  const getSliderMetrics = useCallback(async () => {
    setState((draft) => {
      draft.sliderReady = false;
    });
    // Interactive torso girth slider data
    const INTERACTIVE_SLIDER_METRICS_API_VERSION = 2.6;
    const { collection } = measurementContext.sources.inhouse;
    const metricsApiVersion = await (
      await db.collection(collection).doc(scanId).get()
    ).data().version;

    const torsoGirthsTTF = (
      await db
        .collection(collection)
        .doc(scanId)
        .collection('girths')
        .where('id', 'in', ['torso_girths'])
        .get()
    ).docs[0];
    if (
      Number(metricsApiVersion) >= INTERACTIVE_SLIDER_METRICS_API_VERSION &&
      !!torsoGirthsTTF
    ) {
      const torsoGirths = torsoGirthsTTF.data().torso_girths;
      // Save torso circumference data in array
      setState((draft) => {
        draft.torsoGirths = torsoGirths;
      });

      // Retrieve slicing interval
      const scanDocument = await db.collection(collection).doc(scanId).get();

      const { slicingInterval, floorLevel } = scanDocument?.data() || {};
      setState((draft) => {
        draft.slicingInterval = slicingInterval;
        draft.min = torsoGirths[0]?.level;
        const numSlices = Object.keys(torsoGirths).length;
        draft.max = torsoGirths[numSlices - 1]?.level;
        draft.validSliderVersion = true;
        draft.sliderLevel = draft.max;
        draft.sliderReady = true;
        // set floor level
        draft.floorLevel = floorLevel;
      });
    }
    // Removes the disable on slider checkbox once loading is complete
    setState((draft) => {
      draft.loadingMetrics = false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId, measurementContext.sources.inhouse]);

  useEffect(() => {
    if (!camera.current) return;
    camera.current.fov = fov;
    camera.current?.updateProjectionMatrix();
  }, [fov]);

  useEffect(() => {
    if (
      !visibleContentHeight ||
      state.loadingPercentage <= 0 ||
      !signupBannerHeight
    )
      return;
    setCanvasHeight(
      window.visualViewport.height - visibleContentHeight - signupBannerHeight
    );
    updateCanvasDimensions(
      getWidth(),
      window.visualViewport.height - visibleContentHeight - signupBannerHeight
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visibleContentHeight,
    state.loadingPercentage,
    signupBannerHeight,
    displaySignupBanner,
  ]);

  const animate = useCallback(() => {
    renderer.current?.render(scene.current, camera.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderer.current]);

  const init = useCallback(
    (url) => {
      const width = getWidth();
      const initialCanvasHeight = isMobile ? canvasHeight - 78 : canvasHeight;
      const sceneAlreadyExists = Boolean(scene.current);
      const cameraAlreadyExists = Boolean(camera.current);
      const rendererAlreadyExists = Boolean(renderer.current);
      const controlsAlreadyExists = Boolean(controls.current);
      if (!sceneAlreadyExists) {
        scene.current = new THREE.Scene();
        scene.current.background = new THREE.Color(0x8e919a);
        // lights
        scene.current.add(new THREE.AmbientLight(0x666666));
        const light = new THREE.DirectionalLight(0xdfebff, 0.6);
        light.position.set(0, 50, 100);
        light.position.multiplyScalar(1.3);
        scene.current.add(light);

        const light2 = new THREE.DirectionalLight(0xdfebff, 0.6);
        light2.position.set(0, 50, -100);
        light2.position.multiplyScalar(1.3);
        scene.current.add(light2);

        // Model
        const loader = new OBJLoader();
        loader.load(
          url,
          (object) => {
            object.children[0].geometry.computeBoundingBox();
            const center = object.children[0].geometry.boundingBox.getCenter(
              new THREE.Vector3()
            );
            controls.current.object.position.set(0, center.y, 1.5);
            controls.current.target = center;
            controls.current.saveState();
            animate();

            object.children[0].material = new THREE.MeshPhysicalMaterial();
            object.renderOrder = 0;
            scene.current.add(object);
            window.requestAnimationFrame(() => {
              animate();
            });
            controls.current.update();
          },
          (xhr) => {
            const loadingPercentage = Math.ceil((xhr.loaded / xhr.total) * 100);
            setState((draft) => {
              draft.loadingPercentage = loadingPercentage;
            });
          },
          (error) => {
            console.log(`Error loading obj: ${error}`);
          }
        );
      }
      if (!cameraAlreadyExists) {
        camera.current = new THREE.PerspectiveCamera(
          fov, // fov = field of view
          width / initialCanvasHeight, // aspect ratio
          0.1, // near plane
          1000 // far plane
        );
      }
      // update camera fov and aspect ratio here as well
      camera.current.aspect = width / initialCanvasHeight;
      camera.current.fov = fov;
      camera.current.updateProjectionMatrix();
      camera.current.position.z = 1.5; // is used here to set some distance from the model located at z = 0
      if (!rendererAlreadyExists) {
        renderer.current = new THREE.WebGLRenderer();
        renderer.current.setClearColor(0xeeeeee);
      }
      renderer.current.setSize(width, initialCanvasHeight);
      if (!controlsAlreadyExists) {
        // OrbitControls allow a camera to orbit around the object
        // https://threejs.org/docs/#examples/controls/OrbitControls
        controls.current = new OrbitControls(
          camera.current,
          renderer.current.domElement
        );
        controls.current.addEventListener('change', () => {
          animate();
        });
      }
      const container = document.getElementById('viewer-canvas');
      if (container) {
        container.style.width = `${width}px`;
        container.style.height = `${initialCanvasHeight}px`;
      }
      // add the canvas to the Container only if it's not already added
      // by checking if the container has a canvas child
      if (!container?.querySelector('canvas')) {
        container?.appendChild(renderer.current.domElement);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile]
  );

  const loadMetrics = async () => {
    const { scanDocData } = state;
    const withLegScan = scanDocData?.hasLegScan || scanDocData?.hasFullBodyScan;

    const metrics = await measurementContext.getMetrics(scanId, withLegScan);
    setState((draft) => {
      Object.assign(draft, metrics);
    });

    getSliderMetrics();
    analytics.logEvent('view_avatar', {
      uid: auth.currentUser?.uid || null,
      scanId,
    });
    const timestamp = Math.floor(Date.now() / 1000);
    const userId = auth.currentUser?.uid;
    const { clientId } = measurementContext;
    if (clientId) {
      db.collection(`usage/${clientId}/events`).add({
        clientId,
        scanId,
        timestamp,
        type: 'view',
        userId: userId || null,
      });
    }
  };

  useEffect(() => {
    if (objUrl) {
      init(objUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objUrl]);

  useEffect(() => {
    if (scanId) {
      loadMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    measurementContext.avatarIsPending,
    user?.accountType,
    state.scanDocData,
  ]);

  useEffect(() => {
    const getObjUrl = async () => {
      if (!scanId) return;
      const scan = await db.collection('scans').doc(scanId).get();
      // if scanner has name change page title to this name
      if (scan.data().name) {
        document.title = `${scan.data().name} | Avatar`;
      }
      const scanData = { ...scan.data(), id: scan.id };
      setState((draft) => {
        draft.scanDocData = scanData;
      });
      const objectUrl = `${scan.data().storagePath}/avatar/full_model_mesh.obj`;
      storage
        .child(objectUrl)
        .getDownloadURL()
        .then((url) => {
          setObjUrl(url);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getObjUrl();
    // Cleanup function
    return () => {
      if (controls.current) {
        controls.current.dispose();
      }
      if (renderer.current) {
        document
          .querySelector('#viewer-canvas')
          ?.removeChild(renderer.current.domElement);
        renderer.current?.dispose();
      }
      document.title = 'TrueToForm';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasHeight,
    displaySignupBanner,
    signupBannerHeight,
    visibleContentHeight,
  ]);

  useEffect(() => {
    if (!measurementContext.avatarIsPending) {
      setCheckboxValue((draft) => {
        draft.circCheckboxValue = false;
        draft.lengthCheckboxValue = false;
      });
    }
  }, [measurementContext.avatarIsPending]);

  useEffect(() => {
    if (state.showSlider) {
      // eslint-disable-next-line no-use-before-define
      drawTorsoCircum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderChangesCount]);

  const drawLine = useCallback(
    (points, interactive = false) => {
      const color = new THREE.Color();
      color.setHSL(1.0, 1.0, 0.5);
      if (interactive) {
        color.setHex(0xff0000);
      } else {
        color.setHex(0x0000ff);
      }
      const geometry = new LineGeometry();
      const positions = [];
      const colors = [];
      for (const i in points) {
        const point = points[i];
        positions.push(point.x, point.y, point.z);
        colors.push(color.r, color.g, color.b);
      }
      geometry.setPositions(positions);
      geometry.setColors(colors);

      const lineMaterial = new LineMaterial({
        color,
        linewidth: 0.003,
        dashed: false,
      });
      const line = new Line2(geometry, lineMaterial);
      line.renderOrder = 1;
      scene.current.add(line);
      return line;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scene.current]
  );

  // Draw the torso line corresponding to the current slider value
  const drawTorsoCircum = () => {
    const { metricsLoading } = measurementContext;
    if (metricsLoading) return; // don't draw if metrics are still loading
    // as there won't be any data to draw
    setState((draft) => {
      const {
        min,
        slicingInterval,
        sliderLevel,
        torsoGirths,
        showCirc,
        sliderSlice,
        floorLevel,
      } = draft;
      const slice = sliderSlice;
      if (slice) {
        scene.current?.remove(slice);
      }

      // Find difference in levels to nearest thousandth, then divide by bin size
      const index = Math.trunc((sliderLevel - min) / slicingInterval);
      const sliceInfo = torsoGirths[index];
      const points = sliceInfo?.pointcollection || [];
      const linePoints = [];
      points.forEach((point) => {
        linePoints.push(new THREE.Vector3(point.x, point.y, point.z));
      });
      if (showCirc) {
        linePoints.push(
          new THREE.Vector3(points[0]?.x, points[0]?.y, points[0]?.z)
        );
      }

      draft.sliderSlice = drawLine(linePoints, true);
      draft.currentCircum = convertToInches(sliceInfo.girth);
      // set the height to the current slider level
      if (typeof floorLevel === 'number') {
        draft.currentHeight = convertToInches(sliceInfo.level - floorLevel);
      }
      animate();
    });
  };

  const hideAll = useCallback(() => {
    setState((draft) => {
      const {
        drawnMetrics,
        sliderSlice,
        circData,
        lengthData,
        hasLockedGirthMetrics,
        hasLockedLengthMetrics,
        showCirc,
      } = draft;
      const slice = sliderSlice;
      const hasLockedMetrics = hasLockedGirthMetrics || hasLockedLengthMetrics;
      const selectedViewDrawnMetrics = drawnMetrics.filter((m) => {
        if (hasLockedMetrics) {
          return true;
        }
        const isCirc = !!circData[m];
        return isCirc === showCirc;
      });
      if (slice) {
        scene.current.remove(slice);
        draft.sliderSlice = null;
        draft.showSlider = false;
      }
      if (!selectedViewDrawnMetrics || !selectedViewDrawnMetrics.length) return;
      for (const key of selectedViewDrawnMetrics) {
        const metric = { ...(circData[key] || lengthData[key] || {}) };
        const isCirc = !!circData[key];
        // check if metric is selected
        if (metric.isSelected) {
          scene.current.remove(metric.line);
          metric.isSelected = false;
          metric.line = null;
          if (isCirc) {
            draft.circData[key] = metric;
          } else if (lengthData[key]) {
            draft.lengthData[key] = metric;
          }
        }
      }
      draft.drawnMetrics = drawnMetrics.filter(
        (m) => !selectedViewDrawnMetrics.includes(m)
      );
    });
    animate();
  }, []);

  useEffect(() => {
    hideAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementContext.avatarIsPending, user?.accountType]);

  const sliderChange = (e, newValue) => {
    setState((draft) => {
      const percent = newValue / 100.0;
      const sliderLevel = draft.min + (draft.max - draft.min) * percent;
      draft.sliderPercent = newValue;
      draft.sliderLevel = sliderLevel;
    });
    setSliderChangesCount((oldState) => oldState + 1);
  };

  const toggleMetrics = useCallback(() => {
    setState((draft) => {
      draft.showCirc = !draft.showCirc;
    });
    if (state.showSlider) {
      drawTorsoCircum();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showSlider]);

  const toggleLine = useCallback((id) => {
    setState((draft) => {
      const { circData, lengthData, drawnMetrics } = draft;
      const isCirc = !!circData[id];
      const metric = isCirc ? { ...circData[id] } : { ...lengthData[id] };

      if (metric.line) {
        scene.current.remove(metric.line);
        metric.line = null;
        metric.isSelected = false;
        // remove from drawn metrics
        draft.drawnMetrics = drawnMetrics.filter((m) => m !== id);
      } else {
        const { points = [] } = metric;
        const linePoints = [];
        points.forEach((point) => {
          linePoints.push(new THREE.Vector3(point.x, point.y, point.z));
        });
        if (isCirc) {
          linePoints.push(
            new THREE.Vector3(points[0]?.x, points[0]?.y, points[0]?.z)
          );
        }
        const line = drawLine(linePoints);
        metric.line = line;
        metric.isSelected = true;
        // add to drawn metrics
        draft.drawnMetrics = [...drawnMetrics, id];
      }
      const newCircData = { ...circData };
      const newLengthData = { ...lengthData };
      if (isCirc) {
        newCircData[id] = metric;
      } else {
        newLengthData[id] = metric;
      }
      draft.circData = newCircData;
      draft.lengthData = newLengthData;
    });
    animate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAllLines = useCallback(
    (e) => {
      const {
        showCirc,
        hasLockedGirthMetrics,
        hasLockedLengthMetrics,
        unlockedGirthMetrics,
        unlockedLengthMetrics,
      } = state;
      const checkboxValue = showCirc ? circCheckboxValue : lengthCheckboxValue;
      if (checkboxValue) {
        hideAll();
        setCheckboxValue((draft) => {
          const checkboxKey = showCirc
            ? 'circCheckboxValue'
            : 'lengthCheckboxValue';
          draft[checkboxKey] = false;
        });
        return;
      }
      // if any metrics were drawn, hide them first
      hideAll();
      let metrics = null;
      if (hasLockedGirthMetrics || hasLockedLengthMetrics) {
        metrics = [...unlockedGirthMetrics, ...unlockedLengthMetrics];
      } else {
        metrics = showCirc ? unlockedGirthMetrics : unlockedLengthMetrics;
      }
      // make sure metrics has only valid metrics
      metrics = metrics.filter((m) => {
        const { circData, lengthData } = state;
        const allMetrics = {
          ...circData,
          ...lengthData,
        };
        return !!allMetrics[m];
      });
      if (!metrics || metrics?.length === 0) return;
      const updatedCircData = { ...state.circData };
      const updatedLengthData = { ...state.lengthData };
      const drawnMetrics = [...state.drawnMetrics];
      for (const id of metrics) {
        const { circData, lengthData } = state;
        const allMetrics = {
          ...circData,
          ...lengthData,
        };
        const metric = allMetrics[id] ? { ...allMetrics[id] } : null;
        if (!metric) return;
        const { points } = metric;
        const linePoints = [];
        points.forEach((point) => {
          linePoints.push(new THREE.Vector3(point.x, point.y, point.z));
        });
        const isCirc = !!circData[id];
        if (isCirc) {
          linePoints.push(
            new THREE.Vector3(points[0]?.x, points[0]?.y, points[0]?.z)
          );
        }
        const line = drawLine(linePoints);
        metric.line = line;
        metric.isSelected = true;
        if (isCirc) {
          updatedCircData[id] = metric;
        } else {
          updatedLengthData[id] = metric;
        }
        drawnMetrics.push(id);
      }
      setState((draft) => {
        draft.drawnMetrics = drawnMetrics;
        draft.circData = updatedCircData;
        draft.lengthData = updatedLengthData;
      });
      setCheckboxValue((draft) => {
        const checkboxKey = showCirc
          ? 'circCheckboxValue'
          : 'lengthCheckboxValue';
        draft[checkboxKey] = true;
        if (hasLockedGirthMetrics || hasLockedLengthMetrics) {
          draft.circCheckboxValue = true;
          draft.lengthCheckboxValue = true;
        }
      });
      animate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.showCirc,
      state.hasLockedGirthMetrics,
      state.hasLockedLengthMetrics,
      state.unlockedGirthMetrics,
      state.unlockedLengthMetrics,
      circCheckboxValue,
      lengthCheckboxValue,
    ]
  );

  useEffect(() => {
    animate();
  }, [state.drawnMetrics]);

  const toggleSlider = () => {
    const { accountType, metricsLoading } = measurementContext;
    if (accountType === 'BASIC' || metricsLoading) return;
    setState((draft) => {
      draft.showSlider = !draft.showSlider;
    });
    const slice = state.sliderSlice;
    if (slice) {
      scene.current.remove(slice);
      setState((draft) => {
        draft.sliderSlice = null;
      });
      animate();
    } else {
      drawTorsoCircum();
    }
  };

  const toggleUnits = useCallback(() => {
    setState((draft) => {
      draft.units = draft.units === 'in' ? 'cm' : 'in';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scanDoc = useMemo(
    () => ({
      ...state.scanDocData,
    }),
    [state.scanDocData]
  );

  const isBasicPlan = measurementContext.accountType === 'BASIC';

  return (
    <AvatarViewerProvider
      value={{
        ...state,
        scanDoc,
        hideAll,
        toggleUnits,
        toggleMetrics,
        convertToCm,
        toggleLine,
        isBasicPlan,
        sliderChange,
        toggleSlider,
        metricsLoading: measurementContext.metricsLoading,
      }}
    >
      <Box width="100%" height="100%" className={classes.container}>
        {displaySignupBanner && (
          <div className={classes.signupBanner} ref={signupBannerRef}>
            <SignupBanner />
          </div>
        )}
        <Box
          position="fixed"
          id="viewer-canvas"
          left={0}
          top={displaySignupBanner ? 86 : 0}
          ref={viewerCanvasRef}
        >
          {state.loadingPercentage === 100 && (
            <>
              <Box id="scan-details" className={classes.scanDetails}>
                {!isMobile && <BackButton />}
                <ScanDetailsText />
              </Box>
              <InteractiveSlider />
              <BlackButton onClick={resetView} className={classes.resetButton}>
                Reset View
              </BlackButton>
            </>
          )}
        </Box>
        <MetricsTable
          showCirc={state.showCirc}
          units={state.units}
          metricsLoading={
            measurementContext.metricsLoading || state.loadingMetrics
          }
          loadingPercentage={state.loadingPercentage}
          circData={state.circData}
          hasLockedGirthMetrics={state.hasLockedGirthMetrics}
          hasLockedLengthMetrics={state.hasLockedLengthMetrics}
          hasLegScan={hasLegScan}
          getVisibleContentHeight={setVisibleContentHeight}
          targetContentRef={viewerCanvasRef}
          signupBannerRef={signupBannerRef}
          toggleUnits={toggleUnits}
          toggleAllLines={toggleAllLines}
          circCheckboxValue={circCheckboxValue}
          lengthCheckboxValue={lengthCheckboxValue}
        />
        {state.loadingPercentage !== 100 && (
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100vh', backgroundColor: '#fff' }}
          >
            <Logo />
            Loading {state.loadingPercentage}%
          </Grid>
        )}
      </Box>
    </AvatarViewerProvider>
  );
};

export default withAuthHoc(AvatarViewer);
