import { Box, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as HelpCenterIcon } from '@assets/icons/help-center-icon.svg';

const useStyles = makeStyles(({ palette }) => ({
  link: {
    color: palette.primary.main,
    '&:visited': {
      color: palette.primary.main,
    },
  },
}));

export const EmptyStatesHelpCenter = () => {
  const { link } = useStyles();
  return (
    <Box display="flex" alignItems="center" mt={3}>
      <HelpCenterIcon
        style={{
          marginRight: '4px',
        }}
      />
      <Typography variant="h3" className="h3" noWrap>
        Learn how to{' '}
        <a
          href="https://www.truetoform.fit/help-center#send-avatar-request"
          target="_blank"
          rel="noreferrer"
          className={link}
        >
          request
        </a>{' '}
        and{' '}
        <a
          href="https://www.truetoform.fit/help-center#preview-and-accept-avatar"
          target="_blank"
          rel="noreferrer"
          className={link}
        >
          unlock an avatar
        </a>{' '}
        on our help center
      </Typography>
    </Box>
  );
};
