import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ToasterContextProvider } from '@context/ToasterContext';
import { UserContextProvider } from '@context/UserContext';
import { AuthContextProvider } from '@context/AuthContext';
import { UiContext } from '@context/UiContext';
import { MeasurementContextProvider } from '@src/context/MeasurementContext';
import { Loader } from '@lib/Loader';
import Box from '@material-ui/core/Box';
import Signin from '@components/Auth/Signin';
import Signup from '@components/Auth/Signup';
import VerifyEmail from '@components/Auth/VerifyEmail';
import { ScansContextProvider } from '@context/ScansContext';
import { BillingInfoContextProvider } from '@context/BillingInfoContext';
import { ModalContextProvider } from '@context/ModalContextProvider';
import { useMediaQuery } from '@material-ui/core';
import { useAuthContext } from './hooks/useAuthContext';
import { useModalContext } from './hooks/useModalContext';
import PasswordReset from './pages/PasswordReset';
import AvatarViewer from './pages/AvatarViewer';
import ProtectedPages from './pages/ProtectedPages';
import { DashboardLayout, NoSidebarLayout } from './layouts';
import responsivePages from './constants/responsivePages';

// eslint-disable-next-line import/order
import CssBaseline from '@material-ui/core/CssBaseline';
// eslint-disable-next-line import/order
import { ThemeProvider } from '@material-ui/core/styles';
import { useUser } from './hooks/useUser';

import CustomTheme from './constants/CustomTheme';

import './App.css';

// lazy components
const AuthActions = React.lazy(() => import('./pages/AuthActions'));
const AccountPage = React.lazy(() => import('./pages/Account'));
const MobileDialogue = React.lazy(() =>
  import('./components/Modal/MobileDialogue')
);
const UsageReasonModal = React.lazy(() =>
  import('./components/Modal/UsageReasonModal')
);

const queryClient = new QueryClient();

const Wrapper = ({ children }) => {
  const { openModal, forceCloseModal, metadata } = useModalContext();
  const [modalClosed, setModalClosed] = useState(false);
  const { user } = useAuthContext();
  const prevUser = useRef(user);
  const location = useLocation();
  const { data: userDoc } = useUser();
  const isWelcomed = userDoc?.gotWelcomed;
  const noModalOpen =
    !metadata.openedModal || metadata.openedModal === 'MobileDialogue';
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const shouldDisplayDialogue =
    !isNotMobile &&
    !responsivePages.includes(location.pathname.split('/')[1]) &&
    isWelcomed &&
    // there's an open Modal and it's not the MobileDialogue
    noModalOpen &&
    !modalClosed;
  const showUsageReasonModal = user && !userDoc?.hasSelectedUsageReason;

  useEffect(() => {
    if (showUsageReasonModal) {
      openModal({
        component: UsageReasonModal,
        isPaper: true,
        isClosable: false,
        metadata: {
          openedModal: 'UsageReasonModal',
        },
      });
    } else if (metadata.openedModal === 'UsageReasonModal') {
      forceCloseModal();
    }
  }, [showUsageReasonModal]);

  useEffect(() => {
    if (shouldDisplayDialogue) {
      openModal({
        component: MobileDialogue,
        props: {},
        isPaper: false,
        metadata: {
          openedModal: 'MobileDialogue',
        },
      });
    }
    return () => {
      setModalClosed(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // detect when user logs out
    if (!user && prevUser.current) {
      forceCloseModal();
    }
    prevUser.current = user;
  }, [user, forceCloseModal]);

  return <>{children}</>;
};

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={CustomTheme}>
        <CssBaseline />
        <div className="App">
          <Router>
            <UserContextProvider>
              <QueryClientProvider client={queryClient}>
                <ToasterContextProvider>
                  <AuthContextProvider>
                    <MeasurementContextProvider>
                      <BillingInfoContextProvider>
                        <ScansContextProvider>
                          <UiContext>
                            <ModalContextProvider>
                              <Wrapper>
                                <Switch>
                                  <Route exact path="/">
                                    <Redirect to="/dashboard/active" />
                                  </Route>
                                  <Route
                                    exact
                                    path="/signin"
                                    component={Signin}
                                  />
                                  <Route
                                    exact
                                    path="/signup"
                                    render={(props) => <Signup {...props} />}
                                  />

                                  <Route
                                    exact
                                    path="/passwordReset"
                                    component={PasswordReset}
                                  />

                                  <Route
                                    exact
                                    path="/authActions"
                                    render={() => (
                                      <React.Suspense
                                        fallback={<div>Loading...</div>}
                                      >
                                        <AuthActions />
                                      </React.Suspense>
                                    )}
                                  />
                                  <AppRoute
                                    exact
                                    path="/avatars/viewer/:scanId"
                                    layout={NoSidebarLayout}
                                    component={AvatarViewer}
                                  />
                                  <ProtectedPages>
                                    <Route
                                      path="/dashboard"
                                      component={DashboardLayout}
                                    />
                                    <AppRoute
                                      exact
                                      path="/account"
                                      layout={NoSidebarLayout}
                                      component={AccountPage}
                                    />
                                    <Route
                                      exact
                                      path="/verifyEmail"
                                      component={VerifyEmail}
                                    />
                                  </ProtectedPages>
                                </Switch>
                              </Wrapper>
                            </ModalContextProvider>
                          </UiContext>
                        </ScansContextProvider>
                      </BillingInfoContextProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </MeasurementContextProvider>
                  </AuthContextProvider>
                </ToasterContextProvider>
              </QueryClientProvider>
            </UserContextProvider>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <React.Suspense
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Loader />
            </Box>
          }
        >
          <Component {...props} />
        </React.Suspense>
      </Layout>
    )}
  />
);

export default App;
