import { createContext, useState, useEffect, useRef } from 'react';
import { useUser } from '@hooks/useUser';
import { db } from '@config/firebase';
import { formatScanData } from '@utils/formatScanData';

const ScansContext = createContext();
ScansContext.displayName = 'ScansContext';

const ScansContextProvider = (props) => {
  const { data: user } = useUser();
  const [approvedScans, setApprovedScans] = useState([]);
  const [sharedScans, setSharedScans] = useState([]);
  const [result, setResult] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [pendingScans, setPendingScans] = useState(null);
  const [pendingScansRefs, setPendingScansRefs] = useState({});
  const [pendingScansResult, setPendingScansResult] = useState([]);
  const [unseenScansCount, setUnseenScansCount] = useState(0);
  const unsubscribeScansRef = useRef(() => {});
  const unsubscribeSharedScansRef = useRef(() => {});
  const unsubscribePendingScansRef = useRef(() => {});
  const userAccountType = user?.accountType;

  useEffect(() => {
    let allScans = [...approvedScans];

    if (!user?.isAdmin) {
      const sharedScansIds = sharedScans.map((scan) => scan.id);
      allScans = sharedScansIds.map((id) =>
        approvedScans.find((scan) => scan.id === id)
      );
    }
    const formattedScans = allScans
      .map((scan) =>
        formatScanData({
          scans: allScans,
          metrics,
          scanId: scan?.id,
        })
      )
      .filter(Boolean);
    setResult((prevResult) => {
      if (prevResult !== null && clientId) {
        return {
          ...prevResult,
          [clientId]: formattedScans,
        };
      }
      if (clientId && result === null) {
        return {
          [clientId]: formattedScans,
        };
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedScans, sharedScans]);

  useEffect(() => {
    const allPendingScans = pendingScans
      ?.map((pendingScan) => {
        const originalScanData = approvedScans.find(
          (scan) => scan.id === pendingScan.id
        );
        if (originalScanData) {
          return {
            ...originalScanData,
            ...pendingScan,
            ref: pendingScansRefs[pendingScan.id],
          };
        }
        return null;
      })
      .filter(Boolean);

    const unseenScans = allPendingScans?.filter((scan) => !scan.isSeen);
    setUnseenScansCount(unseenScans?.length);
    setPendingScansResult(allPendingScans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingScans, approvedScans]);

  useEffect(() => {
    if (!clientId) return;
    async function getUserScans() {
      const { isAdmin, accountType } = { ...user };

      if (!isAdmin && accountType === 'LOCKED') {
        return setResult([]);
      }

      const userAccount = isAdmin
        ? await db
            .collection('accounts')
            .where('clientId', '==', clientId)
            .get()
        : await db.collection('accounts').doc(user?.uid).get();

      let scansRef = isAdmin
        ? db
            .collection('scans')
            .where('clientIds', 'array-contains', clientId)
            .orderBy('timestamp', 'desc')
        : db
            .collection('scans')
            .where('clientIds', 'array-contains', clientId)
            .where('pipelineStatus', '==', 'APPROVED')
            .orderBy('timestamp', 'desc');

      if (isAdmin && clientId === 'ALL') {
        scansRef = db.collection('scans').orderBy('timestamp', 'desc');
      }

      const accountRef = isAdmin ? userAccount?.docs[0]?.ref : userAccount?.ref;

      // shared scans are only used for non admin users
      const sharedScansRef = accountRef
        ?.collection('shared_scans')
        .where('shareStatus', '==', 'APPROVED');

      let metricsRef = db
        .collection('metrics_ttf')
        .where('clientIds', 'array-contains', clientId);

      if (isAdmin && clientId === 'ALL') {
        metricsRef = db.collection('metrics_ttf');
      }

      const unsubscribeScans = scansRef.onSnapshot(
        async (querySnapshot) => {
          const scansList = querySnapshot.docs.map((doc) => {
            const scan = doc.data();
            scan.id = doc.id;
            return scan;
          });
          if (scansList.length === 0) return setApprovedScans([]);

          const metricsRes = await metricsRef.get();
          const metricsList = metricsRes.docs.map((doc) => {
            const metricsData = doc.data();
            metricsData.id = doc.id;
            return metricsData;
          });
          setMetrics(metricsList);

          return setApprovedScans(scansList);
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
      unsubscribeScansRef.current = unsubscribeScans;

      const pendingScansRef = accountRef
        ?.collection('shared_scans')
        .where('shareStatus', 'in', ['PENDING', 'REJECTED']);

      const unsubscribePendingScans = pendingScansRef?.onSnapshot(
        (pendingScansDocs) => {
          const pendingScansList = pendingScansDocs.docs.map((doc) => {
            const pendingScan = doc.data();
            pendingScan.id = doc.id;
            return pendingScan;
          });
          const refs = {};
          pendingScansDocs.docs.forEach((doc) => {
            refs[doc.id] = doc.ref;
          });
          setPendingScansRefs(refs);
          return setPendingScans(
            pendingScansList.sort(
              (a, b) => b?.sharedTimestamp - a?.sharedTimestamp
            )
          );
        }
      );
      unsubscribePendingScansRef.current = unsubscribePendingScans;

      if (!isAdmin) {
        const unsubscribeSharedScans = sharedScansRef.onSnapshot(
          async (querySnapshot) => {
            const sharedScansList = querySnapshot.docs.map((doc) => {
              const scan = doc.data();
              scan.id = doc.id;
              return scan;
            });
            if (sharedScansList.length === 0) return setSharedScans([]);

            const metricsRes = await metricsRef.get();
            const metricsList = metricsRes.docs.map((doc) => {
              const metricsData = doc.data();
              metricsData.id = doc.id;
              return metricsData;
            });
            setMetrics(metricsList);
            return setSharedScans(sharedScansList);
          },
          (err) => {
            console.log(`Encountered error: ${err}`);
          }
        );
        unsubscribeSharedScansRef.current = unsubscribeSharedScans;
      }
    }
    if (clientId) {
      getUserScans();
    }
    return () => {
      unsubscribeScansRef.current?.();
      unsubscribeSharedScansRef.current?.();
      unsubscribePendingScansRef.current?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, userAccountType]);

  const clientIds = result ? Object.keys(result) : [];
  const value = {
    result,
    setClientId,
    clientIds,
    pendingScans: pendingScansResult,
    unseenScansCount,
  };

  return <ScansContext.Provider value={value} {...props} />;
};

export { ScansContext, ScansContextProvider };
