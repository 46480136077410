export function openInNewTab(url) {
  const newWindow = window.open(url, '_blank');
  newWindow.opener = window;
}

export function backToOpenerTab() {
  if (window.opener) {
    // focus on the opener tab
    window.opener.focus();
    // close the current tab
    return window.close();
  }
  window.location.href = '/dashboard/active';
}
