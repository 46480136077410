import { useState, useEffect } from 'react';
import { contextFactory } from './createContext';

export const {
  Provider: UiContextProvider,
  useCtx: useUiContext,
  useCtxSelector: useUiContextSelector,
} = contextFactory('UiContext');

export const UiContext = ({ children }) => {
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [shouldCloseSheet, setShouldCloseSheet] = useState(false);

  const closeBottomSheet = () => {
    setShouldCloseSheet(true);
  };

  useEffect(() => {
    if (shouldCloseSheet) {
      setShouldCloseSheet(false);
    }
  }, [shouldCloseSheet]);

  const value = {
    closeBottomSheet,
    setBottomSheetOpen,
    shouldCloseSheet,
  };

  return <UiContextProvider value={value}>{children}</UiContextProvider>;
};
