import { useState, useRef, lazy, Suspense } from 'react';
import { ReactComponent as SortListIcon } from '@assets/icons/sort-list-icon.svg';
import { Loader } from '@lib/Loader';
import { makeStyles } from '@material-ui/core';
import { IconButton } from '../IconButton';

// lazy components
const DropdownMenu = lazy(() => import('./Dropdown'));

const useStyles = makeStyles((theme) => ({
  icon: {
    '& path': {
      fill: '#000',
    },
  },
}));

const SortDropdown = ({ getSortType, adminPortal, sortType }) => {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { icon } = useStyles();

  const handleClick = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        buttonRef={anchorRef}
        onClick={handleClick}
        testid="sort-dropdown"
      >
        <SortListIcon className={icon} />
      </IconButton>
      <Suspense fallback={<Loader />}>
        {anchorEl && (
          <DropdownMenu
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            getSortType={getSortType}
            adminPortal={adminPortal}
            sortType={sortType}
          />
        )}
      </Suspense>
    </>
  );
};

export default SortDropdown;
