import { createContext, useState, useCallback, useRef, Suspense } from 'react';
import { ModalLayout } from '@components/Modal/Layout';
import { Loader } from '@lib/Loader';
import { useUiContext } from '@context/UiContext';

const ModalContext = createContext();
ModalContext.displayName = 'ModalContext';

const DefaultContent = () => '';

const ModalContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPaper, setIsPaper] = useState(true);
  const [Content, setContent] = useState(DefaultContent);
  const [props, setProps] = useState({});
  const [modalClosable, setModalClosable] = useState(true);
  const [metadata, setMetadata] = useState({});
  const onCloseRef = useRef(() => {});
  const { closeBottomSheet } = useUiContext();

  const openModal = useCallback(
    ({
      component,
      props: Props,
      isPaper: isPaperValue = true,
      isClosable = true,
      metadata: metadataArg = {
        openedModal: 'Modal',
      },
    }) => {
      setIsOpen(true);
      setContent(component);
      setProps(Props);
      setIsPaper(isPaperValue);
      setModalClosable(isClosable);
      setMetadata({
        openedModal: 'Modal',
        ...metadataArg,
      });
      closeBottomSheet();
    },
    []
  );

  const onModalClose = useCallback(
    (cb = () => {}) =>
      typeof cb === 'function'
        ? (onCloseRef.current = cb)
        : (() => {
            throw new Error('onModalClose callback must be a function');
          })(),
    []
  );

  const closeModal = useCallback(() => {
    if (!modalClosable || !isOpen) return;
    setIsOpen(false);
    setContent(DefaultContent);
    setProps({});
    setMetadata({});
    const onCloseCB = onCloseRef.current;
    onCloseCB?.();
    onCloseRef.current = () => {};
  }, [isOpen, modalClosable, onCloseRef.current]);

  const forceCloseModal = useCallback(() => {
    if (!isOpen) return;
    setIsOpen(false);
    setContent(DefaultContent);
    setProps({});
    setMetadata({});
    const onCloseCB = onCloseRef.current;
    onCloseCB?.();
    onCloseRef.current = () => {};
  }, [isOpen, onCloseRef.current]);

  const updateProps = (newProps) => {
    setProps({ ...props, ...newProps });
  };

  const value = {
    openModal,
    closeModal,
    forceCloseModal,
    updateProps,
    onModalClose,
    metadata,
  };
  return (
    <ModalContext.Provider value={value}>
      <ModalLayout open={isOpen} onClose={closeModal} isPaper={isPaper}>
        <Suspense fallback={<Loader />}>
          <Content {...props} />
        </Suspense>
      </ModalLayout>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContextProvider, ModalContext };
