/* eslint-disable guard-for-in */
/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-classes-per-file */
import { createContext, useState, useCallback } from 'react';
import { useUser } from '@hooks/useUser';
import { useQueryClient } from 'react-query';
import { db } from '../config/firebase';

const MeasurementContext = createContext({});

class Source {
  constructor(collection, id) {
    this.collection = collection;
    this.id = id;
    this.girthIdToKey = {};
    this.lengthIdToKey = {};
  }
}

const sources = {
  inhouse: new Source('metrics_ttf', 'ttfId'),
  external: new Source('metrics', 'externalId'),
};

class Metric {
  constructor(label, hoverText, ttfId, externalId, isLocked = false) {
    this.isSelected = false;
    this.hoverText = hoverText;
    this.label = label;
    this.line = null;
    this.points = [];
    this.value = 0;
    this.ttfId = ttfId;
    this.externalId = externalId;
    this.isLocked = isLocked;
  }

  lock() {
    this.isLocked = true;
    this.value = '?';
  }

  setValue(value) {
    if (!this.isLocked) {
      this.value = value;
    }
  }
}

const CircumferenceLegMetrics = [
  '13', // Right Thigh
  '12', // Left Thigh
  '11', // Right Knee
  '10', // Left Knee
  '15', // Right Calf
  '14', // Left Calf
  '17', // Right Ankle
  '16', // Left Ankle
];

const LengthsLegMetrics = [
  '103', // Right Outseam Waist To Floor
  '102', // Left Outseam Waist To Floor
  '105', // Right Outseam Waist To Knee
  '104', // Left Outseam Waist To Knee
  '106', // Total Crotch Length
  '126', // Front Crotch Length
  '127', // Back Crotch Length
  '138', // Front Rise - High
  '139', // Back Rise - High
  '136', // Front Rise - Mid
  '137', // Back Rise - Mid
  '134', // Front Rise - Low
  '135', // Back Rise - Low
];

const MeasurementContextProvider = ({ children }) => {
  const { data } = useUser();
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [avatarIsPending, setAvatarIsPending] = useState(true);
  const queryClient = useQueryClient();

  const getData = useCallback(() => {
    // Make sure the name of the Metric matches label from metrics Firestore document
    // The Metric label is the display name, and can differ from Firestore metrics label
    // Circumference Metrics Data
    const circData = {
      21: new Metric('Neck', 'Circumference around neck', 21, 140),
      18: new Metric(
        'Neck Base',
        'Circumference around the base of the neck',
        18,
        127
      ),
      4: new Metric(
        'Upper Chest',
        'Circumference just below the armpits',
        4,
        105
      ),
      20: new Metric(
        'Overbust',
        'Circumference measured from the underbust level at center back, under the armpits, and above the bust at the front',
        20,
        -1
      ),
      2: new Metric(
        'Bust',
        'Circumference around the fullest part of the bust',
        2,
        144
      ),
      9: new Metric('Under Bust', 'Circumference directly below bust', 9, 106),
      3: new Metric(
        'Waist',
        'Circumference measured at the natural waist level',
        3,
        108
      ),
      6: new Metric(
        'Minimum Torso',
        'Minimum circumference of the torso between the chest and hips',
        6,
        155
      ),
      32: new Metric(
        'Waistband - High',
        'Waistband circumference measured at high rise level',
        32,
        -1
      ),
      31: new Metric(
        'Waistband - Mid',
        'Waistband circumference measured at mid rise level',
        31,
        -1
      ),
      30: new Metric(
        'Waistband - Low',
        'Waistband circumference measured at low rise level',
        30,
        -1
      ),
      /*
    19: new Metric('High Hip', 'Circumference around the torso at the level of the pelvic bone', 19, -1),
    */
      5: new Metric(
        'Hip',
        'Circumference around the fullest part of the hips, typically around the buttocks',
        5,
        167
      ),
      29: new Metric(
        'Right Armscye',
        'Right armhole circumference over the top of the shoulder and under the armpit',
        29,
        -1
      ),
      28: new Metric(
        'Left Armscye',
        'Left armhole circumference over the top of the shoulder and under the armpit',
        28,
        -1
      ),
      23: new Metric(
        'Right Bicep',
        'Maximum circumference around the upper right arm',
        23,
        126
      ),
      22: new Metric(
        'Left Bicep',
        'Maximum circumference around the upper left arm',
        22,
        125
      ),
      27: new Metric(
        'Right Elbow',
        'Circumference around the right elbow joint',
        27,
        -1
      ),
      26: new Metric(
        'Left Elbow',
        'Circumference around the left elbow joint',
        26,
        -1
      ),
      25: new Metric(
        'Right Wrist',
        'Circumference around the right wrist joint',
        25,
        121
      ),
      24: new Metric(
        'Left Wrist',
        'Circumference around the left wrist joint',
        24,
        123
      ),
      13: new Metric(
        'Right Thigh',
        'Maximum circumference around the right thigh below the buttocks',
        13,
        142
      ),
      12: new Metric(
        'Left Thigh',
        'Maximum circumference around the left thigh below the buttocks',
        12,
        141
      ),
      11: new Metric(
        'Right Knee',
        'Right knee circumference through center of kneecap',
        11,
        113
      ),
      10: new Metric(
        'Left Knee',
        'Left knee circumference through center of kneecap',
        10,
        114
      ),
      15: new Metric(
        'Right Calf',
        'Maximum circumference around the right calf below the knees and above the ankles',
        15,
        137
      ),
      14: new Metric(
        'Left Calf',
        'Maximum circumference around the left calf below the knees and above the ankles',
        14,
        136
      ),
      17: new Metric(
        'Right Ankle',
        'Minimum circumference around the right leg just above the ankle bone',
        17,
        139
      ),
      16: new Metric(
        'Left Ankle',
        'Minimum circumference around the left leg just above the ankle bone',
        16,
        138
      ),
    };

    const circOrder = [
      '21', // Neck
      '18', // Neck Base
      '4', // Upper Chest
      '20', // Overbust
      '2', // Bust
      '9', // Underbust
      '3', // Waist
      '6', // Minimum Torso
      '32', // Waistband - High
      '31', // Waistband - Mid
      '30', // Waistband - Low
      '5', // Hip
      '29', // Right Armscye
      '28', // Left Armscye
      '23', // Right Bicep
      '22', // Left Bicep
      '27', // Right Elbow
      '26', // Left Elbow
      '25', // Right Wrist
      '24', // Left Wrist
      '13', // Right Thigh
      '12', // Left Thigh
      '11', // Right Knee
      '10', // Left Knee
      '15', // Right Calf
      '14', // Left Calf
      '17', // Right Ankle
      '16', // Left Ankle
    ];

    // Length Metrics Data
    const lengthData = {
      112: new Metric(
        'Right Shoulder',
        'Length from right high point shoulder to right shoulder point, along shoulder slope',
        112,
        302
      ),
      111: new Metric(
        'Left Shoulder',
        'Length from left high point shoulder to left shoulder point, along shoulder slope',
        111,
        301
      ),
      110: new Metric(
        'Right Arm',
        'Length along the outer right arm from the shoulder to elbow to wrist',
        110,
        326
      ),
      109: new Metric(
        'Left Arm',
        'Length along the outer left arm from the shoulder to elbow to wrist',
        109,
        325
      ),
      120: new Metric(
        'Shoulder Width',
        'Length from right shoulder point to left shoulder point over the back neck base',
        120,
        346
      ),
      119: new Metric(
        'Across Chest',
        'Length from armpit to armpit across front along the surface of the body',
        119,
        329
      ),
      121: new Metric(
        'Across Back',
        'Length from armpit to armpit across back along the surface of the body',
        121,
        330
      ),
      125: new Metric(
        'Back Neck to Right Bust',
        'Length from center back neck to right bust point',
        125,
        318
      ),
      124: new Metric(
        'Back Neck to Left Bust',
        'Length from center back neck to left bust point',
        124,
        317
      ),
      116: new Metric(
        'Right High Point Shoulder to Bust',
        'Length from the right side of the neck to the right bust',
        116,
        314
      ),
      115: new Metric(
        'Left High Point Shoulder to Bust',
        'Length from the left side of the neck to the left bust',
        115,
        313
      ),
      129: new Metric(
        'Apex to Apex',
        'Length from left to right bust apex point',
        129,
        -1
      ),
      118: new Metric(
        'Right High Point Shoulder to Waist',
        'Length from the right side of the neck to the waist over the right bust',
        118,
        -1
      ),
      117: new Metric(
        'Left High Point Shoulder to Waist',
        'Length from the left side of the neck to the waist over the left bust',
        117,
        -1
      ),
      128: new Metric(
        'Hollow to Floor',
        'Length from front neck base point to floor level along the center front',
        128,
        -1
      ),
      123: new Metric(
        'Nape to Upper Chest Center Back',
        'Length from back neck base point to scye level along the center back',
        123,
        308
      ),
      122: new Metric(
        'Nape to Waist Center Back',
        'Length from back neck base point to waist level along the center back',
        122,
        309
      ),
      106: new Metric(
        'Total Crotch Length',
        'Length from waist level center front to waist level center back, measured through the crotch',
        106,
        307
      ),
      126: new Metric(
        'Front Crotch Length',
        'Length from waist level center front to the crotch point',
        126,
        -1
      ),
      127: new Metric(
        'Back Crotch Length',
        'Length from waist level center back to the crotch point',
        127,
        -1
      ),
      138: new Metric(
        'Front Rise - High',
        'Length from waistband center front at high rise level to the crotch point',
        138,
        -1
      ),
      139: new Metric(
        'Back Rise - High',
        'Length from waistband center back at high rise level to the crotch point',
        139,
        -1
      ),
      136: new Metric(
        'Front Rise - Mid',
        'Length from waistband center front at mid rise level to the crotch point',
        136,
        -1
      ),
      137: new Metric(
        'Back Rise - Mid',
        'Length from waistband center back at mid rise level to the crotch point',
        137,
        -1
      ),
      134: new Metric(
        'Front Rise - Low',
        'Length from waistband center front at low rise level to the crotch point',
        134,
        -1
      ),
      135: new Metric(
        'Back Rise - Low',
        'Length from waistband center back at low rise level to the crotch point',
        135,
        -1
      ),
      101: new Metric(
        'Right Side Pant Inseam',
        'Length from crotch to floor level, measured along the inner right leg',
        101,
        335
      ),
      100: new Metric(
        'Left Side Pant Inseam',
        'Length from crotch to floor level, measured along the inner left leg',
        100,
        336
      ),
      103: new Metric(
        'Right Outseam Waist To Floor',
        'Length from waist to floor level, measured along the outer right leg',
        103,
        -1
      ),
      102: new Metric(
        'Left Outseam Waist To Floor',
        'Length from waist to floor level, measured along the outer left leg',
        102,
        -1
      ),
      105: new Metric(
        'Right Outseam Waist To Knee',
        'Length from waist to knee level, measured along the outer right leg',
        105,
        -1
      ),
      104: new Metric(
        'Left Outseam Waist To Knee',
        'Length from waist to knee level, measured along the outer left leg',
        104,
        -1
      ),
    };

    const lengthOrder = [
      '112', // Right Shoulder
      '111', // Left Shoulder
      '110', // Right Arm
      '109', // Left Arm
      '120', // Shoulder Width
      '119', // Across Chest
      '121', // Across Back
      '125', // Back Neck to Right Bust
      '124', // Back Neck to Left Bust
      '116', // Right High Point Shoulder to Bust
      '115', // Left High Point Shoulder to Bust
      '129', // Apex to Apex
      '118', // Right High Point Shoulder to Waist
      '117', // Left High Point Shoulder to Waist
      '128', // Hollow to Floor
      '123', // Nape to Upper Chest Center Back
      '122', // Nape to Waist Center Back
      '106', // Total Crotch Length
      '126', // Front Crotch Length
      '127', // Back Crotch Length
      '138', // Front Rise - High
      '139', // Back Rise - High
      '136', // Front Rise - Mid
      '137', // Back Rise - Mid
      '134', // Front Rise - Low
      '135', // Back Rise - Low
      '101', // Right Side Pant Inseam
      '100', // Left Side Pant Inseam
      '103', // Right Outseam Waist To Floor
      '102', // Left Outseam Waist To Floor
      '105', // Right Outseam Waist To Knee
      '104', // Left Outseam Waist To Knee
    ];

    return { circData, circOrder, lengthData, lengthOrder };
  }, []);

  const getMetrics = async (scanId, hasLegScan) => {
    setMetricsLoading(true);
    const { circData, circOrder, lengthData, lengthOrder } = getData();

    const metrics = {
      circData,
      circOrder,
      lengthData,
      lengthOrder,
      hasLockedGirthMetrics: false,
      hasLockedLengthMetrics: false,
      lockedGirthMetrics: [],
      unlockedGirthMetrics: circOrder,
      lockedLengthMetrics: [],
      unlockedLengthMetrics: lengthOrder,
    };

    getIdToKeyMappings(metrics);

    await setUnlockedMetrics(metrics, scanId);
    await getExternalMetrics(metrics, scanId);
    await getTTFMetrics(metrics, scanId);
    if (!hasLegScan) {
      excludeLegMetrics(metrics);
    }
    setMetricsLoading(false);
    return metrics;
  };

  // what this code is doing here is simply checking if
  // the metric has this source id because some metrics might have ttfId only
  // so for each source, it saves the key of the metric that has the source id
  const getIdToKeyMappings = (metrics) => {
    for (const source of Object.values(sources)) {
      for (const key in metrics.circData) {
        if (metrics.circData[key][source.id]) {
          source.girthIdToKey[metrics.circData[key][source.id]] = key;
        }
      }
      for (const key in metrics.lengthData) {
        if (metrics.lengthData[key][source.id]) {
          source.lengthIdToKey[metrics.lengthData[key][source.id]] = key;
        }
      }
    }
  };

  const getPlanData = async (scanId) => {
    if (!scanId) throw new Error('No scanId provided');
    await queryClient.invalidateQueries('user');
    const userData = queryClient.getQueryData('user');
    const { accountType, userDocRef, isAdmin } = { ...userData };
    // check if the user exists
    if (!data || !userDocRef) {
      // return the data for the free plan
      return {
        unlockedGirthMetrics: {
          2: 'Bust',
          3: 'Waist',
          5: 'Hip',
        },
        unlockedLengthMetrics: {
          100: 'Left Side Pant Inseam',
          101: 'Right Side Pant Inseam',
          113: 'Height',
        },
        isDefaultPlan: true,
        avatarPending: true,
        canDownloadMetrics: false,
        canDownloadScans: false,
      };
    }
    // check if the avatar is pending for the user
    let avatarPending = false;
    const sharedScanDoc = await userDocRef
      .collection('shared_scans')
      .doc(scanId)
      .get();
    if (!sharedScanDoc.exists) {
      avatarPending = true;
    } else {
      const sharedScanData = sharedScanDoc.data();
      if (sharedScanData.shareStatus !== 'APPROVED') {
        avatarPending = true;
      }
    }
    if (isAdmin) {
      avatarPending = false;
    }
    setAvatarIsPending(avatarPending);

    const planDoc = await db.collection('plans').doc(accountType).get();
    const planData = planDoc?.data();
    if (!planData) return { avatarPending };
    const billingInfo = await userDocRef
      .collection('invoices')
      .doc('billingInfo')
      .get();
    const billingInfoData = billingInfo.data();
    if (billingInfoData.isCustomPlan) {
      const customPlan = await userDocRef
        .collection('invoices')
        .doc('customPlan')
        .get();
      const customPlanData = customPlan.data();
      return {
        ...planData,
        ...customPlanData,
        avatarPending,
        canDownloadMetrics: avatarPending ? false : planData.canDownloadMetrics,
        canDownloadScans: avatarPending ? false : planData.canDownloadScans,
      };
    }
    return {
      ...planData,
      avatarPending,
      canDownloadMetrics: avatarIsPending ? false : planData.canDownloadMetrics,
      canDownloadScans: avatarIsPending ? false : planData.canDownloadScans,
    };
  };

  const setUnlockedMetrics = async (metrics, scanId) => {
    const planData = await getPlanData(scanId);
    // lock all metrics if plan is default or avatar is unlocked
    if (planData.isDefaultPlan || planData.avatarPending) {
      const allMetrics = Object.values(metrics.circData).concat(
        Object.values(metrics.lengthData)
      );
      allMetrics.forEach((metric) => {
        metric.lock();
      });
    }
    const { unlockedGirthMetrics = {}, unlockedLengthMetrics = {} } = planData;

    if (planData.unlockedGirthMetrics) {
      Object.keys(metrics.circData).forEach((id) => {
        if (!unlockedGirthMetrics.hasOwnProperty(id)) {
          const metric = metrics.circData.find((m) => m[id]);
          // unlock the metric if it is not a child of an unlocked metric
          metric.lock();
        }
      });
    }

    if (planData.unlockedLengthMetrics) {
      Object.keys(metrics.lengthData).forEach((id) => {
        if (!unlockedLengthMetrics.hasOwnProperty(id)) {
          const metric = metrics.lengthData[id];
          // unlock the metric if it is not a child of an unlocked metric
          metric.lock();
        }
      });
    }
    // now sort and reorder the metrics to put the unlocked metrics first
    const theUnlockedGirthMetrics = Object.keys(unlockedGirthMetrics);
    const theUnlockedLengthMetrics = Object.keys(unlockedLengthMetrics);

    const filteredCircOrder = metrics.circOrder.filter(
      (id) => !theUnlockedGirthMetrics.includes(id)
    );
    const filteredLengthOrder = metrics.lengthOrder.filter(
      (id) => !theUnlockedLengthMetrics.includes(id)
    );
    // now add the unlocked metrics to the front of the list
    metrics.circOrder = theUnlockedGirthMetrics.concat(filteredCircOrder);
    metrics.lengthOrder = theUnlockedLengthMetrics.concat(filteredLengthOrder);
    // if it has locked metrics, update the metrics data object
    if (theUnlockedGirthMetrics.length > 0) {
      metrics.hasLockedGirthMetrics = true;
      metrics.unlockedGirthMetrics = theUnlockedGirthMetrics;
      metrics.lockedGirthMetrics = filteredCircOrder;
    }
    if (theUnlockedLengthMetrics.length > 0) {
      metrics.hasLockedLengthMetrics = true;
      metrics.unlockedLengthMetrics = theUnlockedLengthMetrics;
      metrics.lockedLengthMetrics = filteredLengthOrder;
    }
  };

  const excludeLegMetrics = (metrics) => {
    metrics.circOrder = metrics.circOrder.filter(
      (circId) => !CircumferenceLegMetrics.includes(circId)
    );
    metrics.lengthOrder = metrics.lengthOrder.filter(
      (lenId) => !LengthsLegMetrics.includes(lenId)
    );
  };

  const getExternalMetrics = async (metrics, scanId) => {
    const metricsDoc = await db
      .collection(sources.external.collection)
      .doc(scanId)
      .get();
    if (!metricsDoc.data() || !metricsDoc.data().version) {
      // Skip over old metrics that don't have a version field
      return;
    }
    await getGirthMetrics(sources.external, metrics, scanId);
    await getLengthMetrics(sources.external, metrics, scanId);
  };

  const getTTFMetrics = async (metrics, scanId) => {
    await getGirthMetrics(sources.inhouse, metrics, scanId);
    await getLengthMetrics(sources.inhouse, metrics, scanId);
  };

  // meters to in
  const convertToIn = (m) => Math.round(m * 3937.01) / 100;

  const getGirthMetrics = async (source, metrics, scanId) => {
    const girthArrays = [];
    let tempGirthArray = [];
    for (const key in metrics.circData) {
      if (metrics.circData[key][source.id]) {
        tempGirthArray.push(metrics.circData[key][source.id]);
      }
      if (tempGirthArray.length === 10) {
        girthArrays.push(tempGirthArray);
        tempGirthArray = [];
      }
    }
    if (tempGirthArray.length !== 0) {
      girthArrays.push(tempGirthArray);
    }
    let girthMetrics = [];
    for (let i = 0; i < girthArrays.length; i++) {
      const tempGirthMetrics = (
        await db
          .collection(source.collection)
          .doc(scanId)
          .collection('girths')
          .where('id', 'in', girthArrays[i])
          .get()
      ).docs;
      girthMetrics = girthMetrics.concat(tempGirthMetrics);
    }
    for (const key in girthMetrics) {
      // the metric here is the firestore document
      const metric = girthMetrics[key].data();
      const { id } = metric;
      if (metric.girth && id) {
        metrics.circData[id].setValue(convertToIn(metric.girth));
        metrics.circData[id].points = metric.pointcollection;
      }
    }
    // filter out the metrics that don't exist for this scan
    const supportedIds = girthMetrics.map((metric) => metric.id);
    metrics.circOrder = metrics.circOrder.filter((ttfId) =>
      supportedIds.includes(ttfId)
    );
    metrics.unlockedGirthMetrics = metrics.unlockedGirthMetrics.filter(
      (ttfId) => supportedIds.includes(ttfId)
    );
    metrics.lockedGirthMetrics = metrics.lockedGirthMetrics.filter((label) =>
      // here we are just checking if the label is in the supportedIds
      // so the filtering should be done on the both locked and unlocked metrics
      supportedIds.includes(label)
    );
    // delete metrics from circData that don't exist for this scan
    for (const key in metrics.circData) {
      if (!supportedIds.includes(key)) {
        delete metrics.circData[key];
      }
    }
  };

  const getLengthMetrics = async (source, metrics, scanId) => {
    const lengthArrays = [];
    let tempLengthArray = [];
    for (const key in metrics.lengthData) {
      if (metrics.lengthData[key][source.id]) {
        tempLengthArray.push(metrics.lengthData[key][source.id]);
      }
      if (tempLengthArray.length === 10) {
        lengthArrays.push(tempLengthArray);
        tempLengthArray = [];
      }
    }
    if (tempLengthArray.length !== 0) {
      lengthArrays.push(tempLengthArray);
    }
    let lengthMetrics = [];
    for (let i = 0; i < lengthArrays.length; i++) {
      const tempLengthMetrics = (
        await db
          .collection(source.collection)
          .doc(scanId)
          .collection('surfaceLengths')
          .where('id', 'in', lengthArrays[i])
          .get()
      ).docs;
      lengthMetrics = lengthMetrics.concat(tempLengthMetrics);
    }
    for (const key in lengthMetrics) {
      const metric = lengthMetrics[key].data();
      const { id } = metric;
      if (metric.length && id) {
        metrics.lengthData[id].setValue(convertToIn(metric.length));
        metrics.lengthData[id].points = metric.points;
      }
    }
    // filter out the metrics that don't exist for this scan
    const supportedIds = lengthMetrics.map((metric) => metric.id);

    metrics.lengthOrder = metrics.lengthOrder.filter((id) =>
      supportedIds.includes(id)
    );
    metrics.unlockedLengthMetrics = metrics.unlockedLengthMetrics.filter((id) =>
      supportedIds.includes(id)
    );
    metrics.lockedLengthMetrics = metrics.lockedLengthMetrics.filter((id) =>
      supportedIds.includes(id)
    );
    // delete metrics from lengthData that don't exist for this scan
    for (const key in metrics.lengthData) {
      if (!supportedIds.includes(key)) {
        delete metrics.lengthData[key];
      }
    }
  };

  const value = {
    ...data, // user data
    sources,
    getPlanData,
    getMetrics,
    metricsLoading,
    avatarIsPending,
  };

  return (
    <MeasurementContext.Provider value={value}>
      {children}
    </MeasurementContext.Provider>
  );
};

export { MeasurementContext, MeasurementContextProvider };
