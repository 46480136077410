import { ReactComponent as ArrowBackIcon } from '@assets/icons/ic_arrow_back.svg';
import { makeStyles } from '@material-ui/core';
import { backToOpenerTab } from '@utils/browserNavigation';
import { BlackButton } from './BlackButton';

const useStyles = makeStyles(() => ({
  button: {
    margin: 0,
    padding: 8,
    borderRadius: 4,
    minWidth: 40,
  },
}));

export const BackButton = () => {
  const { button } = useStyles();
  return (
    <BlackButton className={button} onClick={backToOpenerTab}>
      <ArrowBackIcon />
    </BlackButton>
  );
};
