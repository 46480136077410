/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const normalTypes = ['a-z', 'asc', 'desc'];
const adminTypes = ['currentUsageDesc', 'totalAmountDesc', 'totalUsageDesc'];

export const sortArr = (arr, type, adminPortal = false) => {
  if (!Array.isArray(arr)) return [];
  const listOfTypes = adminPortal
    ? [...normalTypes, ...adminTypes]
    : normalTypes;
  if (!listOfTypes.includes(type)) {
    throw new Error(
      `useScansSort must be used with a valid type ${listOfTypes} provided: ${type}`
    );
  }

  let sortingType = type;
  let sortKey = null;

  switch (type) {
    case 'a-z':
      sortKey = 'name';
      break;
    case 'asc':
      sortKey = adminPortal ? 'registrationTimestamp' : 'timestamp';
      break;
    case 'desc':
      sortKey = adminPortal ? 'registrationTimestamp' : 'timestamp';
      sortingType = 'desc';
      break;
    case 'currentUsageDesc':
      sortKey = 'billingInfo.currentUsage';
      sortingType = 'desc';
      break;
    case 'totalAmountDesc':
      sortKey = 'billingInfo.totalAmount';
      sortingType = 'desc';
      break;
    case 'totalUsageDesc':
      sortKey = 'billingInfo.totalUsage';
      sortingType = 'desc';
      break;
  }
  if (Array.isArray(arr)) {
    if (sortingType === 'a-z') {
      return arr.sort((a, b) => {
        if (a?.[sortKey] < b?.[sortKey]) return -1;
        if (a?.[sortKey] > b?.[sortKey]) return 1;
        return 0;
      });
    }
    if (sortingType === 'asc') {
      return arr.sort((a, b) => {
        if (a?.[sortKey] < b?.[sortKey]) return -1;
        if (a?.[sortKey] > b?.[sortKey]) return 1;
        return 0;
      });
    }
    if (sortingType === 'desc') {
      return arr.sort((a, b) => {
        if (sortKey.includes('billingInfo')) {
          const key = sortKey.split('.')[1];
          a = a.billingInfo;
          b = b.billingInfo;
          return b[key] - a[key];
        }
        if (a?.[sortKey] < b?.[sortKey]) return 1;
        if (a?.[sortKey] > b?.[sortKey]) return -1;
        return 0;
      });
    }
  }
  return [...arr];
};
