import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import UserContext from '@context/UserContext';
import { useModalContext } from '@hooks/useModalContext';
import { useUser } from '@hooks/useUser';
import { useAuthContext } from '@hooks/useAuthContext';
import { protectedUrls } from '@src/constants';
import { useQueryClient } from 'react-query';
import { fetchInvoices } from '@hooks/useInvoices';
import { fetchPaymentMethods } from '@hooks/usePaymentMethods';
import { ReactComponent as Logo } from '../img/logo.svg';

const PausedAccountModal = React.lazy(() =>
  import('@components/Modal/PausedAccount')
);
const PlanEndedModal = React.lazy(() => import('@components/Modal/PlanEnded'));
const WelcomeModal = React.lazy(() => import('@components/Modal/WelcomeModal'));

const ProtectedPages = ({ children }) => {
  const { setUser } = useContext(UserContext);
  const { openModal } = useModalContext();
  const { user } = useAuthContext();
  const { data: account } = useUser();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const { gotWelcomed, accountType, planEnded } = { ...account };

    // show paused Modal if user has LOCKED account type
    if (accountType === 'LOCKED') {
      return openModal({
        component: PausedAccountModal,
        props: {
          isLocked: true,
          accountType,
        },
        isClosable: false,
        metadata: {
          isClosable: false,
          openedModal: 'PausedAccountModal',
        },
      });
    }
    const noWelcome = !gotWelcomed && user && account?.accountType === 'BASIC';

    const showWelcomeModal = noWelcome;

    if (showWelcomeModal && protectedUrls.includes(pathname.split('/')[1])) {
      openModal({
        component: WelcomeModal,
        isPaper: false,
        metadata: {
          openedModal: 'WelcomeModal',
        },
      });
    }

    if (planEnded) {
      openModal({
        component: PlanEndedModal,
        metadata: {
          openedModal: 'PlanEndedModal',
        },
      });
    }

    if (account && account.clientId) {
      if (accountType !== 'BASIC') {
        // prefetch useInvoices and usePaymentMethods if they are not already fetched
        // so that when users visit account page they won't have to wait for the data to be fetched
        const invoicesQueryData = queryClient.getQueryData('invoices');
        const paymentMethodsQueryData =
          queryClient.getQueryData('paymentMethods');

        if (!invoicesQueryData) {
          queryClient.prefetchQuery('invoices', () => fetchInvoices());
        }

        if (!paymentMethodsQueryData) {
          queryClient.prefetchQuery('paymentMethods', () =>
            fetchPaymentMethods()
          );
        }
      }
      setUser(account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pathname, setUser]);

  // don't show any protected content if user is scanner/has locked account
  const shouldHideProtectedContent = account?.accountType === 'LOCKED';
  if (
    shouldHideProtectedContent &&
    protectedUrls.includes(pathname.split('/')[1])
  ) {
    return null;
  }

  // no need to check for clientId from this Component as the AuthContext handles this behavior
  return user ? (
    <div>{children}</div>
  ) : (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Logo />
    </Grid>
  );
};

export default ProtectedPages;
