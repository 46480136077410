import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as EmptyAvatarIcon } from '@assets/icons/ic_empty_avatar.svg';
import { EmptyStatesHelpCenter } from '@src/components/ui/EmptyStatesHelpCenter';

class EmptyAvatarPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.parentContainer} data-testid="empty-avatars-page">
        <Container className={classes.container} component="main">
          <Box width="200px" height="200px" mb={4}>
            <EmptyAvatarIcon className={classes.imageIcon} />
          </Box>
          <Typography className={classes.title} variant="h3">
            No Unlocked Avatars
          </Typography>
          <Typography
            className={clsx('p1', classes.text)}
            variant="body2"
            align="center"
          >
            Avatars that you have unlocked are added to your avatar library. Get
            started by sending an avatar request to the left, or generate your
            own avatar using our iOS scan app.
          </Typography>
          <EmptyStatesHelpCenter />
        </Container>
      </Box>
    );
  }
}

const styles = (theme) => ({
  parentContainer: {
    height: '90%',
  },
  container: {
    position: 'relative',
    top: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.black,
    fontWeight: 600,
    marginBottom: '16px',
    'font-style': 'SemiBold',
    'font-size': '28px',
    lineheight: '40px',
    'line-height': '77%',
    align: 'center',
    'vertical-align': 'center',
  },
  text: {
    color: '#8A8A98',
    textAlign: 'center',
    width: '570px',
    maxWidth: '100%',
  },
  imageIcon: {
    width: 200,
    height: 200,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
});

export default withStyles(styles)(EmptyAvatarPage);
