import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { ReactComponent as AccountPausedIcon } from '@assets/icons/account-paused-icon.svg';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxWidth: '100vw',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 4),
    },
  },
  paper: {
    padding: theme.spacing(3, 2),
    textAlign: 'center',
    background: '#fff',
    width: 500,
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(-50%)',
      padding: theme.spacing(4, 6, 3, 6),
    },
  },
  icon: {
    marginBottom: theme.spacing(3),
  },
  titleStyles: {
    marginBottom: 36,
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  primaryButton: {
    borderRadius: 148,
    padding: theme.spacing(1, 3),
  },
}));

const Container = ({ children }) => {
  const { container } = useStyles();
  return <div className={container}>{children}</div>;
};

const AvatarPaper = ({ children, title }) => {
  const { paper, icon, titleStyles } = useStyles();
  return (
    <Container>
      <div className={paper}>
        <AccountPausedIcon className={icon} />
        <Typography variant="h1" className={clsx(titleStyles, 'h1', 'black')}>
          {title}
        </Typography>
        {children}
      </div>
    </Container>
  );
};

const Paragraph = ({ children }) => {
  const { paragraph } = useStyles();
  return (
    <Typography variant="body1" className={clsx(paragraph, 'p1', 'dark-grey')}>
      {children}
    </Typography>
  );
};

const PrimaryButton = ({ children, ...rest }) => {
  const { primaryButton } = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      className={primaryButton}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const AvatarNotFound = () => {
  const history = useHistory();
  return (
    <AvatarPaper title="Uh oh, avatar not found">
      <Paragraph>
        You don't have permission to view this avatar, or this avatar does not
        exist.
      </Paragraph>
      <Button
        variant="text"
        onClick={() => {
          history.push('/dashboard/active');
        }}
        color="primary"
      >
        Back to dashboard
      </Button>
    </AvatarPaper>
  );
};

export const AvatarRequiresLogin = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleNavigation = (to) => {
    const location = {
      pathname: to,
      state: { from: pathname },
    };
    history.push(location);
  };

  return (
    <AvatarPaper title="Log in to continue to TrueToForm">
      <Paragraph>
        Access to this page is restricted. Sign in or create an account below.
      </Paragraph>
      <PrimaryButton
        onClick={() => {
          handleNavigation('/signin');
        }}
      >
        Log in
      </PrimaryButton>
      <Button
        variant="text"
        onClick={() => {
          handleNavigation('/signup');
        }}
        color="primary"
      >
        Sign up
      </Button>
    </AvatarPaper>
  );
};
