import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Button, Typography, Box } from '@material-ui/core';
import { Badge } from '@lib/Badge';
import { useUser } from '@hooks/useUser';
import { useBillingInfoContext } from '@hooks/useBillingInfoContext';
import { ReactComponent as UnlockIcon } from '@assets/icons/ic_unlocked.svg';
import { ReactComponent as ToastErrorIcon } from '@assets/icons/ic_tooltip.svg';
import { ReactComponent as ToastSuccessIcon } from '@assets/icons/ic_checked_green.svg';
import { db, firebase } from '@config/firebase';
import { useToasterContext } from '@hooks/useToasterContext';
import { useParams } from 'react-router-dom';
import { MeasurementContext } from '@context/MeasurementContext';
import { retry } from '@utils/retry';
import { useUiContext } from '@context/UiContext';
import loaderImg from '@src/img/loader-icon.png';
import whiteLoaderImg from '@assets/icons/ButtonLoading.png';
import { OpenModalButton } from '../Modal/OpenModalButton';

// lazy Components
const OutOfCredits = React.lazy(() => import('@components/Modal/OutOfCredits'));

const useStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 12px',
    overflow: 'hidden',
    width: '100%',
    borderBottom: `2px solid ${palette.lightGrey.main}`,
  },
  button: {
    borderRadius: 148,
    padding: '8px 24px 8px 16px',
    alignSelf: 'flex-start',
  },
  icon: {
    marginRight: 6,
    '& path': {
      fill: (props) =>
        props.disabled ? palette.darkGrey.main : palette.white.main,
    },
  },
  badge: {
    marginBottom: 8,
  },
  greenIcon: {
    fill: '#00A931 !important',
    '& path': {
      fill: '#00A931 !important',
    },
  },
}));

const SectionContent = ({
  accountType,
  totalThisMonth,
  totalUsage,
  basicCredits,
}) => {
  const { badge } = useStyles();

  if (!accountType) {
    return null;
  }

  if (accountType !== 'BASIC') {
    return (
      <div>
        <Badge type={accountType} className={badge}>
          {accountType}
        </Badge>
        <Typography variant="body1" className="p1 dark-grey">
          <span style={{ fontWeight: 700, color: '#000' }}>
            {totalThisMonth}
          </span>{' '}
          unlocked this month
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography
        variant="body1"
        className="p1 dark-grey"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <span style={{ fontWeight: 700, color: '#000' }}>
          {totalUsage}/{totalUsage + basicCredits}
        </span>
        &nbsp;free unlocks used
      </Typography>
      <Typography variant="body1" className="p1 dark-grey">
        Each free unlock provides <br /> access to basic measurements
      </Typography>
    </div>
  );
};

const WhiteSpinner = () => (
  <img
    src={whiteLoaderImg}
    alt="loader"
    className="loading"
    width={24}
    height={24}
  />
);

const UnlockButton = () => {
  const { data: user } = useUser();
  const { userDocRef, accountType, uid, clientId } = { ...user };
  const [isUnlocking, setIsUnlocking] = useState(false);
  const { button, icon, greenIcon } = useStyles({ disabled: isUnlocking });
  const {
    basicCredits: contextBasicCredits = 0,
    totalUsage,
    fetchData,
  } = useBillingInfoContext();
  const scanId = useParams().scanId || '';
  const { showToaster } = useToasterContext();
  const { getPlanData } = useContext(MeasurementContext);
  const { closeBottomSheet } = useUiContext();
  const isOutOfCredits = accountType === 'BASIC' && contextBasicCredits === 0;

  const handleUnSharedScan = async (currentScanId) => {
    const sharedScanRef = userDocRef
      .collection('shared_scans')
      .doc(currentScanId);
    const sharedScanDoc = await sharedScanRef.get();
    if (sharedScanDoc.exists) return true;
    // check if the scan is taken by the user
    const scanRef = db.collection('scans').doc(currentScanId);
    const scanDoc = await scanRef.get();
    if (!scanDoc.exists) return false;
    // share the scan with the user and wait for the shared scan to be created
    await scanRef.update({
      clientIds: firebase.firestore.FieldValue.arrayUnion(clientId),
    });
    await retry(
      async () => {
        const doc = await sharedScanRef.get();
        if (!doc.exists) {
          throw new Error('Shared Scan not found');
        }
        return doc;
      },
      {
        retries: 10,
        delay: 1000,
      }
    );
    return true;
  };

  const approvePendingAvatar = async () => {
    if (!scanId) return;
    const scanRef = userDocRef.collection('shared_scans').doc(scanId);
    try {
      setIsUnlocking(true);
      closeBottomSheet();
      showToaster({
        message: 'Unlocking Avatar',
        icon: <WhiteSpinner />,
      });
      await handleUnSharedScan(scanId);
      if (accountType === 'BASIC') {
        await db.runTransaction(async (transaction) => {
          const billingInfoRef = userDocRef
            .collection('invoices')
            .doc('billingInfo');
          const billingInfoDoc = await transaction.get(billingInfoRef);
          const { basicCredits } = billingInfoDoc.data();
          if (basicCredits < 1) {
            throw new Error('Insufficient Credits');
          }
          const approvalTimestamp = Math.round(Date.now() / 1000);
          transaction.update(billingInfoRef, {
            basicCredits: basicCredits - 1, // decrement basic credits by 1 should fail if basicCredits < 1
            latestApprovalTimestamp: approvalTimestamp,
          });
          // if basicCredits < 1, the above update will fail and the following code will not run
          transaction.update(scanRef, {
            shareStatus: 'APPROVED',
            isSeen: true,
            approvedTimestamp: approvalTimestamp,
            isServerUpdate: false,
            isFromBasicPlan: true,
          });
        });
        await fetchData({
          totalUsage: totalUsage + 1,
        }); // optimistically update the totalUsage by incrementing it by 1
        await getPlanData(scanId);
        showToaster({
          message: 'Avatar unlocked!',
          icon: <ToastSuccessIcon className={greenIcon} />,
        });
        setIsUnlocking(false);
        return;
      }
      await scanRef.update({
        shareStatus: 'APPROVED',
        isSeen: true,
        approvedTimestamp: Math.round(Date.now() / 1000),
        isServerUpdate: false,
        isFromBasicPlan: firebase.firestore.FieldValue.delete(), // remove isFromBasicPlan field if it exists
      });
      await getPlanData(scanId);
      showToaster({
        message: 'Avatar unlocked!',
        icon: <ToastSuccessIcon className={greenIcon} />,
      });
      setIsUnlocking(false);
    } catch (error) {
      console.error('Error while Approving Scan', error);
      showToaster({
        message: 'Error Unlocking Avatar!',
        icon: <ToastErrorIcon />,
      });
      setIsUnlocking(false);
    }
  };

  useEffect(
    () => () => {
      setIsUnlocking(false);
    },
    []
  );

  if (isOutOfCredits) {
    return (
      <OpenModalButton component={OutOfCredits}>
        <Button variant="contained" color="primary" className={button}>
          <UnlockIcon className={icon} />
          Unlock
        </Button>
      </OpenModalButton>
    );
  }

  if (isUnlocking) {
    return (
      <Box
        width={120}
        height={48}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={loaderImg}
          alt="loader"
          className="loading"
          width={24}
          height={24}
        />
      </Box>
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      className={button}
      onClick={approvePendingAvatar}
      disabled={isUnlocking}
    >
      <UnlockIcon className={icon} />
      Unlock
    </Button>
  );
};

export const UnlockAvatarBlock = () => {
  const { container } = useStyles();
  const {
    totalThisMonth,
    totalUsage,
    basicCredits = 0,
  } = useBillingInfoContext();
  const { data: user } = useUser();
  const scanId = useParams().scanId || '';
  const { accountType, userDocRef } = { ...user };

  useEffect(() => {
    if (scanId) {
      const sharedScanRef = userDocRef.collection('shared_scans').doc(scanId);
      sharedScanRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data.shareStatus === 'PENDING') {
            sharedScanRef.update({
              isSeen: true,
            });
          }
        }
      });
    }
  }, [scanId, userDocRef]);

  return (
    <div className={container}>
      <SectionContent
        accountType={accountType}
        totalThisMonth={totalThisMonth}
        totalUsage={totalUsage}
        basicCredits={basicCredits}
      />
      <UnlockButton accountType={accountType} basicCredits={basicCredits} />
    </div>
  );
};
