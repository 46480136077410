import React from 'react';

export const IconButton = ({ children, buttonRef, testid, ...rest }) => (
  <button
    style={{
      border: 'none',
      background: 'none',
      outline: 'none',
      padding: 0,
      cursor: 'pointer',
    }}
    ref={buttonRef}
    {...rest}
    data-testid={testid}
  >
    {children}
  </button>
);
