import {
  createContext,
  useContextSelector,
  useContext,
} from 'use-context-selector';

export const contextFactory = (displayName) => {
  const context = createContext();
  context.displayName = displayName;

  const useCtx = () => {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error('useCtx must be inside a Provider with a value');
    }
    return ctx;
  };

  const useCtxSelector = (contextSelector) => {
    const selector = (state) => {
      if (!state) {
        throw new Error(
          'useCtxSelector must be inside a Provider with a value'
        );
      }
      return contextSelector(state);
    };
    return useContextSelector(context, selector);
  };

  return {
    Provider: context.Provider,
    useCtx,
    useCtxSelector,
  };
};
