/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import { createContext, useState, useEffect, useCallback } from 'react';
import {
  auth,
  db,
  analytics,
  sendAuthEmails,
  createNewUser,
} from '@config/firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { fetchUserDoc } from '@hooks/useUser';
import { sendEmail } from '@utils/sendEmail';
import { FullPageLoader } from '@src/lib/FullPageLoader';
import { useToasterContext } from '@hooks/useToasterContext';
import { retry } from '@src/utils/retry';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [hasValidData, setHasValidData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const queryClient = useQueryClient();
  const history = useHistory();
  const { pathname } = useLocation();
  const { showToaster } = useToasterContext();
  const [tokenLoading, setTokenLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get('token');

  useEffect(() => {
    async function loginWithToken() {
      if (tokenParam) {
        // delete token from url
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        const userCreds = await auth.signInWithCustomToken(tokenParam);
        const { uid } = userCreds.user;
        setUser(userCreds.user);
        await queryClient.fetchQuery('user', () => fetchUserDoc(uid));
        await queryClient.invalidateQueries('user');
        setTokenLoading(false);
      } else {
        setTokenLoading(false);
      }
    }
    loginWithToken();
  }, [tokenParam, queryClient]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (!authUser) {
        setAuthLoading(false);
      }
      setUser(authUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // use retry to retry the function if it fails
  async function checkValidData() {
    const validateUser = async () => {
      setAuthLoading(true);
      setIsLoading(true);
      const { uid } = user;
      const userDocRef = db.collection('accounts').doc(uid);
      try {
        const unsubscribe = userDocRef.onSnapshot(async (userDoc) => {
          if (userDoc.exists) {
            const { clientId } = userDoc.data();
            if (clientId) {
              setHasValidData(true);
              await queryClient.fetchQuery('user', () => fetchUserDoc(uid));
              setIsReady(true);
              setIsLoading(false);
              setAuthLoading(false);
              unsubscribe();
              return true;
            }
            throw new Error(`missing clientId for user ${uid}`);
          }
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    return retry(validateUser, {
      retries: 6,
      delay: 1000,
    });
  }

  useEffect(() => {
    if (!user) {
      const authRoutes = [
        '/signup',
        '/passwordReset',
        '/authActions',
        '/signin',
      ];
      if (!authRoutes.includes(pathname)) {
        // exclude the avatars route from redirecting to signin
        if (pathname.includes('avatars')) return;
        // the app initially starts with this page, so this wouldn't have effect
        // if user was logged in. then logs out, this would have effect
        const location = {
          pathname: '/signin',
          state: { from: pathname },
        };
        return history.push(location);
      }
    } else {
      // eslint-disable-next-line no-inner-declarations
      async function callCheckValidData() {
        try {
          await checkValidData();
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
      callCheckValidData();
    }
  }, [user]);

  const login = useCallback(({ email, password }) => {
    setIsLoading(true);
    return auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, []);

  const sendVerificationEmail = async (authUser) => {
    const {
      data: { message },
    } = await sendAuthEmails({
      email: authUser.email,
      type: 'verifyEmail',
    });
    analytics.logEvent('send_verification_email', {
      uid: authUser.uid,
    });
    showToaster({
      message,
    });
    let userDoc = await db.collection('accounts').doc(authUser.uid).get();
    if (!userDoc.exists) {
      userDoc = await db.collection('scanner_accounts').doc(authUser.uid).get();
    }
    await userDoc.ref.update({
      verificationEmailSent: true,
    });
    return true;
  };

  const signup = useCallback(
    async ({
      email,
      password,
      orgName = '',
      firstName,
      lastName,
      usageReason,
    }) => {
      try {
        setIsLoading(true);
        const validOptions = ['business', 'personal', 'both'];
        const usageReasonParam = validOptions.includes(usageReason)
          ? usageReason
          : undefined;
        const userResult = await createNewUser({
          email,
          password,
          orgName,
          firstName,
          lastName,
          usageReason: usageReasonParam,
        });
        const {
          data: { token },
        } = userResult;
        // login user with token
        await auth.signInWithCustomToken(token);
        setIsLoading(false);
        analytics.logEvent('signup', {
          uid: userResult.data.uid,
          orgName,
          firstName,
          lastName,
          email,
        });
        const name = `${firstName} ${lastName}`;
        await sendEmail({
          to: [
            process.env.NODE_ENV === 'development'
              ? process.env.REACT_APP_DEV_EMAIL
              : 'hello@truetoform.fit',
          ],
          type: 'sendNewUserEmail',
          subject: `${name} has joined TrueToForm`,
          templateData: { name, email },
        });
      } catch (err) {
        analytics.logEvent('signup_error', {
          error: err.message,
          email,
        });
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const logout = () => {
    auth.signOut().then(() => {
      queryClient.clear();
    });
  };

  if (authLoading) return <FullPageLoader />;

  const value = {
    user,
    hasValidData,
    isLoading,
    login,
    signup,
    isReady,
    sendVerificationEmail,
    logout,
    checkValidData,
    tokenLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
