/* eslint-disable jsx-a11y/label-has-associated-control */
import { makeStyles } from '@material-ui/core';
import { useState, useRef, useEffect } from 'react';

const useStyles = makeStyles(({ palette }) => ({
  switch: {
    borderRadius: 6,
    position: 'relative',
    display: 'inline-flex',
    padding: 2,
    background: palette.lightGrey.main,
    '& input': {
      display: 'none',
    },
    cursor: 'pointer',
    overflow: 'hidden',
  },
  option: {
    padding: '4px 6px',
    borderRadius: 4,
    lineHeight: '26px',
    fontWeight: 600,
    color: '#000',
    cursor: 'pointer',
    minWidth: 36,
    textAlign: 'center',
    zIndex: 1,
    fontSize: ({ fontSize }) => fontSize,
  },
  toggleIndicator: {
    position: 'absolute',
    top: 2,
    left: 2,
    height: 'calc(100% - 4px)',
    borderRadius: 4,
    background: palette.white.main,
    transition: 'transform 0.2s ease-in-out',
  },
}));

export const SwitchButton = ({
  options,
  onChange = () => {},
  fontSize = 14,
}) => {
  if (!Array.isArray(options) || options.length !== 2) {
    throw new Error('SwitchButton component requires an array of two options');
  }
  const defualtValue = options[0];
  const [value, setValue] = useState(defualtValue);
  const [transform, setTransform] = useState(0);
  const [indiacatorWidth, setIndicatorWidth] = useState(0);
  const classes = useStyles({
    fontSize,
  });
  const optionOneRef = useRef(null);
  const optionTwoRef = useRef(null);

  useEffect(() => {
    const optionOneWidth = optionOneRef.current.offsetWidth;
    const optionTwoWidth = optionTwoRef.current.offsetWidth;
    setIndicatorWidth(value === options[1] ? optionTwoWidth : optionOneWidth);
    setTransform(value === options[1] ? optionOneWidth : 0);
  }, [optionOneRef, optionTwoRef, value, options]);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const updatedValue = isChecked ? options[1] : options[0];
    setValue(updatedValue);
    onChange(updatedValue);
  };

  return (
    <label className={classes.switch}>
      <input type="checkbox" onChange={handleChange} />
      <span
        className={classes.toggleIndicator}
        style={{
          width: indiacatorWidth,
          transform: `translateX(${transform}px)`,
        }}
      />
      <span className={classes.option} ref={optionOneRef}>
        {options[0]}
      </span>
      <span className={classes.option} ref={optionTwoRef}>
        {options[1]}
      </span>
    </label>
  );
};
