import { useQuery } from 'react-query';
import { getPaymentMethods } from '@config/firebase';

const fetchPaymentMethods = async () => {
  const { data } = await getPaymentMethods();
  const defaultPaymentMethod = data.find((pm) => pm.isDefault);
  return {
    paymentMethods: data,
    defaultPaymentMethod,
  };
};

const usePaymentMethods = () => {
  const { data, ...rest } = useQuery(
    'paymentMethods',
    () => fetchPaymentMethods(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
  return {
    ...rest,
    paymentMethods: data?.paymentMethods,
    defaultPaymentMethod: data?.defaultPaymentMethod,
  };
};

export { usePaymentMethods, fetchPaymentMethods };
