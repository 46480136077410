import { cloneElement } from 'react';
import { useModalContext } from '@hooks/useModalContext';
import { callAll } from '@utils/callAll';

export const OpenModalButton = ({
  children: child,
  component,
  props,
  isPaper = true,
  metadata = {},
}) => {
  const { openModal } = useModalContext();
  return cloneElement(child, {
    onClick: callAll(child.props.onClick, () =>
      openModal({
        component,
        props,
        isPaper,
        metadata,
      })
    ),
  });
};
