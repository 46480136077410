import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    padding: 12,
    paddingTop: 8,
    backgroundColor: palette.extraDarkGrey.main,
    borderRadius: 8,
    color: '#fff',
  },
}));

export const TooltipContent = ({ heading, text }) => {
  const { container } = useStyles();
  return (
    <div className={container}>
      <Typography
        variant="h3"
        className="h3"
        style={{ textAlign: 'center', margin: 0 }}
      >
        {heading}
      </Typography>
      <Typography
        variant="body1"
        className="p2"
        style={{ margin: 0, color: '#fff' }}
      >
        {text}
      </Typography>
    </div>
  );
};
