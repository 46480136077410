import React, { useContext, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useAuthContext } from '@hooks/useAuthContext';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Loader } from '@lib/Loader';
import { firebaseAuthErrorsMap } from '@utils/firebaseAuthErrorsMap';
import { formContext, FormError } from './Form';
import { Input } from './Input';
import { withFormContext } from './withFormContext';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    width: 408,
    maxWidth: '100%',
  },
  input: {
    marginBottom: 8,
  },
  buttonContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  textButtonContainer: {
    marginTop: 16,
    textAlign: 'center',
    '& button': {
      padding: 0,
    },
  },
  button: {
    width: 148,
    borderRadius: '52px',
    padding: 10,
  },
}));

const Signin = () => {
  const { buttonContainer, button, textButtonContainer, container, input } =
    useStyles();
  const { login, user, isReady, isLoading } = useAuthContext();
  const context = useContext(formContext);
  const { setError, handleSubmit, errorMessage } = { ...context };
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    handleSubmit(() => async (e) => {
      e.preventDefault();
      const { email, password } = e.target.elements;
      try {
        await login({ email: email.value, password: password.value });
      } catch (error) {
        if (error.code) {
          if (firebaseAuthErrorsMap[error.code]) {
            setError(firebaseAuthErrorsMap[error.code]);
          } else {
            setError({
              name: 'email',
              message: 'Something went wrong',
            });
          }
        } else {
          setError({
            name: 'email',
            message: 'Something went wrong',
          });
        }
      }
    });
  }, []);

  if (user && isReady) {
    const previousPath = location.state?.from ? location.state.from : '/';
    return <Redirect to={previousPath} />;
  }

  return (
    <>
      <FormError errorMessage={errorMessage} />
      <div className={container}>
        <Input
          labelText="Email"
          name="email"
          required
          type="email"
          disabled={isLoading}
          classes={{ root: input }}
        />
        <Input
          labelText="Password"
          name="password"
          required
          type="password"
          disabled={isLoading}
          classes={{ root: input }}
        />
        <div className={buttonContainer}>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={button}
              disabled={isLoading}
              type="submit"
            >
              Log in
            </Button>
          )}
        </div>
        <div className={textButtonContainer}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              history.push('/passwordReset');
            }}
          >
            Forgot password?
          </Button>
        </div>
      </div>
    </>
  );
};

export default withFormContext(Signin);
