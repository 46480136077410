import { useEffect } from 'react';
import { useUser } from '@hooks/useUser';
import { useAuthContext } from '@hooks/useAuthContext';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useScanData } from '@hooks/useScanData';
import { AvatarNotFound, AvatarRequiresLogin } from './AvatarPagePapers';

export const withAuthHoc = (Component) =>
  function AvatarViewerHoc(props) {
    const { data: user, isLoading: userLoading } = useUser();
    const scanId = useParams().scanId || null;
    const { data: scanData, isLoading: scanDataLoading } = useScanData(scanId);
    const { tokenLoading, user: authUser } = useAuthContext();
    const queryClient = useQueryClient();

    useEffect(() => {
      async function revalidateScan() {
        if (scanId && authUser) {
          await queryClient.invalidateQueries(['scan', scanId]);
        }
      }
      revalidateScan();
    }, [authUser, scanId, queryClient]);

    const scanNotFound =
      scanId && !scanData && !scanDataLoading && !tokenLoading;
    const userNotSignedIn = !user && !userLoading && !tokenLoading;

    if (scanNotFound && !userNotSignedIn) {
      return <AvatarNotFound />;
    }

    if (userNotSignedIn && scanNotFound) {
      return <AvatarRequiresLogin />;
    }

    return <Component {...props} />;
  };
