import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 12px',
    borderRadius: 20,
    textTransform: 'uppercase',
    background: (props) =>
      props.type === 'PRO'
        ? theme.palette.gradient.pro
        : props.type === 'STANDARD'
        ? theme.palette.gradient.standard
        : props.type === 'BASIC'
        ? '#8C99C8'
        : theme.palette.gradient.basic,
    fontSize: 12,
    color: '#FFF',
    fontWeight: 700,
    lineHeight: '23.8px',
    letterSpacing: '0.052em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'fit-content',
  },
}));

export const Badge = ({ className, type, children }) => {
  const { container } = useStyles({ type });
  return (
    <span
      className={clsx(container, className)}
      data-testid="account-type-badge"
    >
      {type === 'BASIC' ? 'No Plan' : children}
    </span>
  );
};
