import { useContext, useEffect, useState } from 'react';
import { ScansContext } from '@context/ScansContext';
import { useUser } from './useUser';

export function useScans(clientId = null) {
  const context = useContext(ScansContext);

  if (!context) {
    throw new Error('useScans must be used within a ScansContextProvider');
  }
  const { isSuccess: loadedUserSuccess } = useUser();
  if (!clientId && loadedUserSuccess) {
    throw new Error('useScans must be used with a clientId');
  }

  const { result, setClientId, clientIds, pendingScans, unseenScansCount } =
    context;
  const [scans, setScans] = useState(null);

  useEffect(() => {
    // prevent resubscribing to the same clientId
    if (clientId && !clientIds.includes(clientId)) {
      setClientId(clientId);
    }
    if (result?.[clientId]) {
      setScans(result[clientId]);
    }
  }, [clientId, clientIds, result, setClientId]);

  return {
    scans,
    pendingScans,
    isLoading: [pendingScans, scans].includes(null),
    unseenScansCount,
  };
}
