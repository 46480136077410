export function formatSearchScans(isAdmin, scans) {
  if (!isAdmin) {
    return scans?.map(({ ...scan }) => ({
      name: scan.name,
      email: scan.email,
      pose: scan.pose,
      hips: scan.hips,
      waist: scan.waist,
      bust: scan.bust,
      timestamp: scan.timestamp,
      id: scan.id,
      storagePath: scan.storagePath,
      height: scan.height,
      weight: scan.weight,
      age: scan.age,
      hasLegScan: scan.hasLegScan || scan.hasFullBodyScan,
    }));
  }
  return scans;
}
