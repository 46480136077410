import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useRef, useEffect } from 'react';

const useStyles = makeStyles(({ breakpoints }) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    padding: '24px 0',
    borderTop: '1px solid #D9E4FE',
    alignSelf: 'stretch', // for flex conatiner that has direction column
    '&:before, &:after': {
      content: '""',
      height: 1,
      width: (props) => props.parentPaddingX,
      backgroundColor: '#D9E4FE',
      position: 'absolute',
      top: -1,
    },
    '&:before': {
      left: 0,
      transform: 'translateX(-100%)',
    },
    '&:after': {
      right: 0,
      transform: 'translateX(100%)',
    },
    [breakpoints.up('sm')]: {
      position: 'relative',
      borderTop: 'none',
      padding: 0,
      '&:before, &:after': {
        display: 'none',
      },
    },
  },
}));

const findScrollableParent = (element) => {
  /**
   * This function will find the scrollable parent of an element
   * for the StickyButtonContainer component the scrollable element
   * needs to be the element that has the padding otherwise
   * the logic used here will not work
   */
  let parent = element.parentElement;
  while (parent) {
    // return the element that has overflow-y is scroll
    if (window.getComputedStyle(parent).overflowY === 'scroll') {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
};

export const StickyButtonContainer = ({
  children,
  parentPaddingX = 24,
  parentPaddingBottom = 24,
}) => {
  const { buttonContainer } = useStyles({
    parentPaddingX,
    parentPaddingBottom,
  });
  const ref = useRef(null);
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  useEffect(() => {
    const scrollableParent = findScrollableParent(ref.current);
    if (!scrollableParent) return;
    scrollableParent.style.overflowX = 'hidden';
    scrollableParent.style.paddingBottom = `${parentPaddingX}px`;
    if (!isNotMobile) {
      scrollableParent.style.paddingBottom = 0;
    } else {
      scrollableParent.style.paddingBottom = `${parentPaddingX}px`;
    }
  }, [isNotMobile, parentPaddingX]);

  return (
    <div className={buttonContainer} ref={ref}>
      {children}
    </div>
  );
};
