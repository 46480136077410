import { memo, useMemo, useContext, useRef, useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Typography,
  Checkbox,
  useMediaQuery,
} from '@material-ui/core';
import { ExportRow } from '@src/components/AvatarViewer/ExportRow';
import { ScanTagging } from '@src/layouts/ScanTagging';
import { MetricsRows } from '@components/AvatarViewer/MetricsRows';
import { useUser } from '@hooks/useUser';
import { MeasurementContext } from '@context/MeasurementContext';
import { ReactComponent as Logo } from '../../img/logo.svg';
import { IOSDrawerWrapper } from './IOSDrawerWrapper';
import { SwitchButton } from './SwitchButton';
import { ReactComponent as CheckboxOnIcon } from '../../img/checkbox-on.svg';
import { ReactComponent as CheckboxOffIcon } from '../../img/checkbox-off.svg';
import { SignupBanner } from './SignupBanner';
import { UnlockAvatarBlock } from './UnlockAvatarBlock';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#fff',
  },
  tableHeaderCell: {
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: '#EEF0F9',
  },
  checkBox: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
}));

export const MetricsTable = memo(
  ({
    toggleUnits,
    metricsLoading,
    loadingPercentage,
    circData,
    hasLockedGirthMetrics,
    hasLockedLengthMetrics,
    hasLegScan,
    getVisibleContentHeight,
    targetContentRef,
    toggleAllLines,
    circCheckboxValue,
    lengthCheckboxValue,
    showCirc,
    signupBannerRef,
  }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 1200px)');
    const { data: user } = useUser();
    const { avatarIsPending } = useContext(MeasurementContext);
    const showUnlockAvatarBlock = avatarIsPending && !!user && !metricsLoading;
    const visibleContentRef = useRef(null);
    const [visibleContentHeight, setVisibleContentHeight] = useState(0);
    const topMobileSpace = isMobile ? 60 : 0;
    const containerMobileStyle = isMobile
      ? {}
      : {
          position: 'fixed',
          top: 0,
          width: '30%',
          height: '100%',
          left: '70%',
        };

    useEffect(() => {
      if (visibleContentRef.current) {
        setVisibleContentHeight(visibleContentRef.current.clientHeight);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleContentRef.current]);

    const subtractedHeight = isMobile
      ? topMobileSpace + visibleContentHeight + 210 // padding bottom
      : 0;

    const visibleContent = useMemo(
      () => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          py={2}
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            borderRadius: '8px 8px 0 0',
          }}
          ref={visibleContentRef}
        >
          <div
            style={{
              width: 44,
              height: 4,
              backgroundColor: '#D9D9D9',
              borderRadius: 4,
              marginBottom: 16,
            }}
          />
          <Typography variant="h3" className="h3 black">
            View Measurements
          </Typography>
        </Box>
      ),
      []
    );
    const hasLockedMetrics = hasLockedGirthMetrics || hasLockedLengthMetrics;
    const checked = hasLockedMetrics
      ? circCheckboxValue && lengthCheckboxValue
      : showCirc
      ? circCheckboxValue
      : lengthCheckboxValue;

    return (
      <IOSDrawerWrapper
        visibleContent={visibleContent}
        getVisibleContentHeight={getVisibleContentHeight}
        targetContentRef={targetContentRef}
        signupBannerRef={signupBannerRef}
      >
        <Box className={classes.container} style={containerMobileStyle}>
          {metricsLoading && loadingPercentage === 100 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              minHeight={`calc(100vh - ${subtractedHeight}px)`}
            >
              <Logo width="86px" height="86px" />
            </Box>
          ) : (
            <>
              {!hasLegScan() && loadingPercentage === 100 && <ScanTagging />}
              {!isMobile && !user && !metricsLoading && <SignupBanner />}
              {!!user && avatarIsPending && !metricsLoading && (
                <UnlockAvatarBlock />
              )}
              {loadingPercentage === 100 && circData && (
                <Box>
                  {hasLockedGirthMetrics || hasLockedLengthMetrics ? null : (
                    <ExportRow />
                  )}
                  <TableContainer>
                    <Table
                      stickyHeader
                      aria-label="Circumference metrics table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.tableHeaderCell}
                            colSpan={6}
                          >
                            {hasLockedGirthMetrics || hasLockedLengthMetrics
                              ? 'Basic Measurements'
                              : 'Measurements'}
                          </TableCell>
                          <TableCell
                            className={classes.tableHeaderCell}
                            align="center"
                            colSpan={2}
                            style={{ fontWeight: 800 }}
                          >
                            <SwitchButton
                              options={['IN', 'CM']}
                              onChange={toggleUnits}
                              fontSize={12}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableHeaderCell}
                            colSpan={1}
                          >
                            <Checkbox
                              disableRipple
                              className={classes.checkBox}
                              checkedIcon={<CheckboxOnIcon />}
                              icon={<CheckboxOffIcon />}
                              onClick={toggleAllLines}
                              disabled={metricsLoading}
                              checked={checked}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <MetricsRows />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </>
          )}
        </Box>
      </IOSDrawerWrapper>
    );
  }
);

MetricsTable.displayName = 'MetricsTable';
