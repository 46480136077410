import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.extraLightGrey.main,
    padding: "0px",
    minHeight: "100vh",
  },
}));

export const NoSidebarLayout = (props) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <main className={classes.content}>{props.children}</main>
    </Box>
  );
};
