/* eslint-disable camelcase */
import { useQuery } from 'react-query';
import { db, auth } from '@config/firebase';
import moment from 'moment';

const fetchInvoices = async () => {
  const { uid } = { ...auth.currentUser };
  const accountRef = db.collection('accounts').doc(uid);
  const invoicesRef = accountRef.collection('invoices');
  const invociesResponse = await invoicesRef
    .orderBy('chargeDate', 'desc')
    .get();

  let unpaidOverageInvoice = invociesResponse.docs.find(
    (doc) => doc.data()?.overageChargeStatus === 'BILLED'
  );
  if (unpaidOverageInvoice) {
    unpaidOverageInvoice = {
      ...unpaidOverageInvoice.data(),
      id: unpaidOverageInvoice.id,
    };
  }

  const filteredInvoices = invociesResponse.docs.filter(
    (doc) =>
      doc.data().chargeDate &&
      (doc.data().billingStatus === 'PAID' ||
        doc.data().overageChargeStatus === 'PAID')
  );
  const userInvoices = filteredInvoices.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  // as invoices are ordered by date, the previous invoice will be the second one
  let recentPreviousInvoice = userInvoices[0];
  if (userInvoices.length > 1) {
    // eslint-disable-next-line prefer-destructuring
    recentPreviousInvoice = userInvoices[1];
  }

  let previousInvoicePaymentDate = 'N/A';
  if (
    recentPreviousInvoice &&
    (recentPreviousInvoice.billingStatus === 'PAID' ||
      recentPreviousInvoice.overageChargeStatus === 'PAID')
  ) {
    previousInvoicePaymentDate = moment(
      recentPreviousInvoice.chargeDate
    ).format('MM/DD/yyy');
  }

  const invoices = userInvoices.map((invoice, idx) => {
    let previousInvoice = invoice;
    previousInvoice = userInvoices[idx + 1] || invoice;
    const lastInvoicePaymentDate = moment(previousInvoice.chargeDate).format(
      'MM/DD/yyy'
    );
    return {
      ...invoice,
      lastInvoiceDate: lastInvoicePaymentDate,
    };
  });

  return {
    invoices,
    previousInvoicePaymentDate,
    unpaidOverageInvoice,
  };
};

const useInvoices = () => {
  const { data, ...rest } = useQuery('invoices', () => fetchInvoices(), {
    refetchOnWindowFocus: false,
    staleTime: window.Cypress ? 3000 : Infinity,
    refetchInterval: window.Cypress ? 3000 : false,
  });
  return {
    ...rest,
    invoices: data?.invoices,
    previousInvoicePaymentDate: data?.previousInvoicePaymentDate,
    unpaidOverageInvoice: data?.unpaidOverageInvoice,
  };
};

export { useInvoices, fetchInvoices };
