import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    padding: '8px 16px',
    textDecoration: 'none',
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 8,
    '&:hover': {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.black.main,
    },
  },
}));

export const BlackButton = ({ children, ...props }) => {
  const { button } = useStyles();
  return (
    <Button onClick={props.onClick} className={clsx(button, props.className)}>
      {children}
    </Button>
  );
};
