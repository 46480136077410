import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// avoiding error for duplicated app name
const firebaseApp = firebase.apps[0]
  ? firebase.apps[0]
  : firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const auth = firebaseApp.auth();
const db = firebaseApp.firestore();
// possible fix to the bug with realtime snapshots
db.settings({ experimentalForceLongPolling: true });
const functions = firebase.functions();
const storage = firebase.storage().ref();

// callable Functions definitions
const createNewUser = functions.httpsCallable('createNewUser');
const setAccountType = functions.httpsCallable('setAccountType');
const addAdmin = functions.httpsCallable('addAdmin');
const deleteAccount = functions.httpsCallable('deleteAccount');
const sendEmailCaller = functions.httpsCallable('sendEmailCaller');
const setupIntent = functions.httpsCallable('setupIntent');
const payWithIntent = functions.httpsCallable('payWithIntent');
const getPaymentMethods = functions.httpsCallable('getPaymentMethods');
const attachPaymentMethod = functions.httpsCallable('attachPaymentMethod');
const updateDefaultPayementMethod = functions.httpsCallable(
  'updateDefaultPayementMethod'
);
const deletePaymentMethod = functions.httpsCallable('deletePaymentMethod');
const payInvoiceManually = functions.httpsCallable('payInvoiceManually');
const getUnpaidInvoice = functions.httpsCallable('getUnpaidInvoice');
const createInstantPayment = functions.httpsCallable('createInstantPayment');
const sendAuthEmails = functions.httpsCallable('sendAuthEmails');

// init Emulators
const EMULATORS_STARTED = false;

function startEmulators() {
  if (!global[EMULATORS_STARTED]) {
    global[EMULATORS_STARTED] = true;
    db.settings({ experimentalForceLongPolling: true });
    db.useEmulator('localhost', 8080);
    firebase.auth().useEmulator('http://localhost:9099/');
    firebase.functions().useEmulator('localhost', 5001);
    firebase.storage().useEmulator('localhost', 9199);
  }
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_EMULATORS_MODE === 'on'
) {
  console.log('Emulators mode enabled');
  startEmulators();
}

export {
  analytics,
  auth,
  db,
  functions,
  storage,
  firebase,
  createNewUser,
  setAccountType,
  addAdmin,
  deleteAccount,
  sendEmailCaller,
  setupIntent,
  payWithIntent,
  getPaymentMethods,
  attachPaymentMethod,
  updateDefaultPayementMethod,
  deletePaymentMethod,
  payInvoiceManually,
  getUnpaidInvoice,
  createInstantPayment,
  sendAuthEmails,
};
