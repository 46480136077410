/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { makeStyles, Typography, Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as DownArrow } from '@assets/icons/ic_arrow_down.svg';
import { ReactComponent as UpArrow } from '@assets/icons/ic_arrow_up.svg';

const useStyles = makeStyles((theme) => ({
  detailsStyles: {
    backgroundColor: 'rgba(242, 189, 53, 0.12)',
    padding: '8px 12px',
  },
  content: {
    color: theme.palette.darkGrey.main,
    paddingTop: 4,
    paddingBottom: 4,
    maxWidth: '100%',
  },
  tagContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 8,
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: 0,
    },
  },
  tag: {
    width: 57,
    backgroundColor: '#fff',
    borderRadius: '50px',
    fontFamily: 'Hind Siliguri',
    fontSize: 12,
    color: '#000',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'center',
    marginRight: 24,
  },
}));

const Content = ({ isOpen }) => {
  const { content } = useStyles();
  return (
    <Collapse in={isOpen}>
      <Typography variant="body2" className={clsx('p2', content)}>
        This Avatar was created using an older version of the TrueToForm
        scanning application which does not include a leg scan. Request that a
        new Avatar be created using app version 5.0 or later to get a more
        accurate Avatar with newly available scan inputs.
      </Typography>
    </Collapse>
  );
};

export const ScanTagging = () => {
  const { detailsStyles, tag, tagContainer } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={detailsStyles}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className={tagContainer}>
        <Typography variant="h3" className="h3">
          Scan Inputs
        </Typography>
        <div className={tag}>TORSO</div>
        {isOpen ? <UpArrow /> : <DownArrow />}
      </div>
      <Content isOpen={isOpen} />
    </div>
  );
};
