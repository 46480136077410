/* eslint-disable import/no-cycle */
import { useLayoutEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as MenuIcon } from '@assets/icons/ic_burger_menu.svg';
import { DrawerContext } from '@src/layouts/DashboardLayout';
import clsx from 'clsx';
import ProfileMenu from './ProfileMenu';
import { Search } from './ProfileMenu/Search';
import SortDropdown from './ProfileMenu/SortDropdown';

const SIDEBAR_WIDTH = 309;
const CONTAINER_MARGIN = 60;
const OFFSET = SIDEBAR_WIDTH + CONTAINER_MARGIN;

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: '48px',
    fontWeight: '600',
    lineHeight: '56px',
    // font-family: 'Hind Siliguri', sans-serif;
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '32px',
    },
  },
}));

function TopBar(props) {
  const { titleStyle } = useStyles();
  const { title, getSortType, getSearchValue, reset, adminPortal, sortType } =
    props;
  const { setDrawerOpen } = useContext(DrawerContext);
  const location = useLocation();
  const shouldDisplayFiltering =
    !location.pathname.includes('dashboard/pending');
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const [width, setWidth] = useState(
    window.innerWidth - (isDesktop ? OFFSET : CONTAINER_MARGIN)
  );

  useLayoutEffect(() => {
    function handleResize() {
      const newMargin = isDesktop ? CONTAINER_MARGIN : 32;
      setWidth(window.innerWidth - (isDesktop ? OFFSET : newMargin));
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call immediately to initialize on mount

    return () => window.removeEventListener('resize', handleResize);
  }, [isDesktop]); // Only recalculate if isDesktop changes

  return (
    <Box
      style={{
        maxWidth: `${width}px`,
        position: 'sticky',
        left: isDesktop ? `${OFFSET}px` : '0px',
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={8} container>
          <Hidden mdUp>
            <IconButton
              disableRipple
              style={{
                padding: '4px',
                marginRight: '16px',
              }}
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h1" className={clsx(titleStyle)}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ProfileMenu
            getSortType={getSortType}
            getSearchValue={getSearchValue}
            reset={reset}
            adminPortal={adminPortal}
            sortType={sortType}
          />
        </Grid>
        <Hidden smUp>
          {shouldDisplayFiltering && (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              style={{
                gap: '16px',
                paddingTop: '14px',
              }}
            >
              <Search getSearchValue={getSearchValue} reset={reset} />
              <SortDropdown
                getSortType={getSortType}
                adminPortal={adminPortal}
                sortType={sortType}
              />
            </Box>
          )}
        </Hidden>
      </Grid>
    </Box>
  );
}

TopBar.defaultProps = {
  getSortType: () => {},
  getSearchValue: () => {},
  reset: () => {},
  adminPortal: false,
  onSort: () => {},
};

export default TopBar;
