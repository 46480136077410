import React, { useMemo, memo, useContext } from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import { useAvatarViewerSelector } from '@context/AvatarViewerContext';
import { MeasurementContext } from '@context/MeasurementContext';
import { useUser } from '@hooks/useUser';
import { OpenModalButton } from '../Modal/OpenModalButton';
import { SwitchButton } from './SwitchButton';

const ExportFile = React.lazy(() => import('../Modal/ExportFile'));

const useStyles = makeStyles(({ palette }) => ({
  exportButton: {
    padding: '8px 24px',
    borderRadius: 70,
    border: `1px solid ${palette.lightGrey.main}`,
    backgroundColor: 'transparent',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',
    },
    '&:disabled': {
      color: palette.mediumGrey.main,
      cursor: 'not-allowed',
    },
  },
}));

const ExportRowNotMemoized = () => {
  const { scanDoc, toggleMetrics } = useAvatarViewerSelector((state) => ({
    scanDoc: state.scanDoc,
    toggleMetrics: state.toggleMetrics,
  }));
  const { avatarIsPending } = useContext(MeasurementContext);
  const { data: user } = useUser();
  const { exportButton } = useStyles();
  const { accountType } = user || {};
  const modalProps = useMemo(
    () => ({
      scan: scanDoc,
      scanId: scanDoc.id,
      accountType,
    }),
    [accountType, scanDoc]
  );
  return (
    <Box
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: '#EEF0F9',
        border: '1px 0 solid #D9E4FE',
      }}
    >
      <SwitchButton
        options={['Circumferences', 'Lengths']}
        onChange={toggleMetrics}
        fontSize={14}
      />
      <OpenModalButton component={ExportFile} props={modalProps}>
        <Button
          variant="outlined"
          color="primary"
          className={exportButton}
          disabled={avatarIsPending}
        >
          Export
        </Button>
      </OpenModalButton>
    </Box>
  );
};

export const ExportRow = memo(ExportRowNotMemoized);
ExportRow.displayName = 'ExportRow';
