import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { ToasterContext } from '@context/ToasterContext';

import { sendAuthEmails } from '../config/firebase';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      success: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '', success: '' });
    if (this.state.email === '') {
      this.setState({ error: 'Email id is empty' });
      return;
    }

    sendAuthEmails({
      email: this.state.email,
      type: 'resetPassword',
    })
      .then(() => {
        this.setState({
          success: 'Reset Password Email sent',
          email: '',
          error: '',
        });
        const { showToaster } = this.context;
        showToaster({
          message: 'reset email sent',
        });
      })
      .catch(() => {
        this.setState({ error: 'Error resetting password' });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOpenIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>

            {this.state.error && (
              <Box mt={2} width={1}>
                <Alert severity="error">{this.state.error}</Alert>
              </Box>
            )}
            {this.state.success && (
              <Box mt={2} width={1}>
                <Alert severity="success">{this.state.success}</Alert>
              </Box>
            )}

            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                autoFocus
                id="email"
                label="Email Address"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                className={classes.submit}
              >
                Send me a reset link
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="../signin" variant="body2">
                    &larr; back to sign in page
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </Box>
    );
  }
}

PasswordReset.contextType = ToasterContext;

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(PasswordReset);
