export async function retry(fn, { retries = 3, delay = 300 }) {
  let result;
  try {
    result = await fn();
  } catch (e) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay));
      return retry(fn, { retries: retries - 1, delay });
    }
    throw e;
  }
  return result;
}
