import { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { ReactComponent as Logo } from '../img/logo.svg';

export const FullPageLoader = () => {
  const [height, setHeight] = useState(0);
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  useEffect(() => {
    setHeight(window.visualViewport.height);
  }, [isNotMobile]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: `${height}px` }}
      data-testid="full-page-loader"
    >
      <Logo />
    </Grid>
  );
};
