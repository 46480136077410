export const firebaseAuthErrorsMap = {
  'auth/email-already-in-use': {
    name: 'email',
    message: 'Email already registered',
  },
  'auth/email-already-exists': {
    name: 'email',
    message: 'Email already registered',
  },
  'auth/invalid-email': {
    name: 'email',
    message: 'Invalid email',
  },
  'auth/user-not-found': {
    name: 'email',
    message: 'User not found',
  },
  'auth/wrong-password': {
    name: 'password',
    message: 'Wrong password',
  },
  'auth/weak-password': {
    name: 'password',
    message: 'Password is too weak',
  },
  'auth/invalid-credential': {
    name: 'email',
    message: 'Invalid credential',
  },
  'auth/operation-not-allowed': {
    name: 'email',
    message: 'Operation not allowed',
  },
};
