/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { makeStyles, Typography, Collapse, Button } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as DownArrow } from '@assets/icons/ic_arrow_down.svg';
import { ReactComponent as UpArrow } from '@assets/icons/ic_arrow_up.svg';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  detailsStyles: {
    backgroundColor: '#fff',
    padding: '20px 24px',
  },
  visibleContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  content: {
    color: theme.palette.darkGrey.main,
    marginTop: 20,
    maxWidth: '100%',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  buttonContainer: {
    marginRight: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    borderRadius: 148,
    padding: theme.spacing(1, 3),
  },
  coloredText: {
    background:
      'linear-gradient(84.38deg, #5C9DE9 -16%, #C08BFF 45.1%, #AB8BFF 107.5%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));

const Content = ({ isOpen }) => {
  const { content } = useStyles();
  return (
    <Collapse in={isOpen}>
      <Typography variant="body2" className={clsx('p2', content)}>
        Create an account to start unlocking avatar measurements, exporting
        avatar files and more, for free! <br />
        Learn more at{' '}
        <a href="https://truetoform.fit" target="_blank" rel="noreferrer">
          TrueToForm.fit
        </a>
      </Typography>
    </Collapse>
  );
};

export const SignupBanner = () => {
  const {
    detailsStyles,
    visibleContent,
    buttonContainer,
    button,
    coloredText,
  } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleNavigation = (to) => {
    const location = {
      pathname: to,
      state: { from: pathname },
    };
    history.push(location);
  };

  return (
    <div className={detailsStyles}>
      <div
        className={visibleContent}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className={buttonContainer}>
          <div>
            <Typography variant="h2" className={clsx('h2', coloredText)}>
              Unlock avatar data!
            </Typography>
            <Typography variant="body2" className="p2 dark-grey">
              Get started for free
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={button}
            onClick={() => {
              handleNavigation('/signup');
            }}
          >
            Sign up
          </Button>
        </div>
        {isOpen ? <UpArrow /> : <DownArrow />}
      </div>
      <Content isOpen={isOpen} />
    </div>
  );
};
