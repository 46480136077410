import { useUser } from '@hooks/useUser';

function ErrorFallback({ error }) {
  const { data: user } = useUser();
  const isAdmin = user?.isAdmin;
  return (
    <div role="alert" style={{ height: '100%', backgroundColor: '#fff' }}>
      <p>Something went wrong:</p>
      {isAdmin && <pre style={{ color: 'red' }}>{error.message}</pre>}
    </div>
  );
}

export default ErrorFallback;
