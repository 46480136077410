import { sendEmailCaller } from '@config/firebase';

const emailTypes = Object.freeze({
  SEND_AVATAR_REQUEST: {
    type: 'sendAvatarRequest',
    templateId: process.env.REACT_APP_SEND_AVATAR_REQUEST_TEMPLATE_ID,
    checkTemplateData(data) {
      const { orgName, clientId, message } = data;
      if (
        typeof orgName !== 'string' ||
        typeof clientId !== 'string' ||
        typeof message !== 'string'
      ) {
        throw new Error('missing or invalid template data');
      }
      return true;
    },
  },
  SEND_CONTACT_US_EMAIL: {
    type: 'sendContactUsEmail',
    templateId: process.env.REACT_APP_CONTACT_US_TEMPLATE_ID,
    checkTemplateData(data) {
      const { name, email, message, clientId } = data;
      if (
        typeof name !== 'string' ||
        typeof email !== 'string' ||
        typeof message !== 'string' ||
        typeof clientId !== 'string'
      ) {
        throw new Error('missing or invalid template data');
      }
      return true;
    },
  },
  SEND_NEW_USER_EMAIL: {
    type: 'sendNewUserEmail',
    templateId: process.env.REACT_APP_NEW_USER_TEMPLATE_ID,
    checkTemplateData(data) {
      const { name, email } = data;
      if (typeof name !== 'string' || typeof email !== 'string') {
        throw new Error('missing or invalid template data');
      }
      return true;
    },
  },
});

export async function sendEmail({
  to,
  cc = [],
  bcc = [],
  subject,
  templateData,
  type,
}) {
  if (!Array.isArray(to)) {
    throw new Error('to must be an array!');
  }

  if (!Array.isArray(cc) || !Array.isArray(bcc)) {
    throw new Error('cc and bcc must be arrays!');
  }

  if (!subject) {
    throw new Error('subject must be a string!');
  }

  const typesArray = Object.values(emailTypes).map((item) => item.type);

  if (!type || !typesArray.includes(type)) {
    throw new Error(
      `type must be a valid email type! one of ${typesArray.join(', ')}`
    );
  }

  const selectedType = Object.values(emailTypes).find(
    (item) => item.type === type
  );

  const { templateId } = selectedType;

  // eslint-disable-next-line no-constant-condition
  if (!typeof templateId === 'string' || !templateId) {
    // don't invoke the cloud function if the templateId is not set
    throw new Error(`missing or invalid template id for subject ${subject}`);
  }

  if (selectedType.checkTemplateData(templateData)) {
    await sendEmailCaller({
      to,
      cc,
      bcc,
      subject,
      templateId,
      templateData,
    });
  }
}
