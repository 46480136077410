import React, { useContext, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as ErrorIcon } from '@assets/icons/ic_error.svg';
import { useLocation } from 'react-router-dom';
import { formContext } from './Form';

const useStyles = makeStyles(({ palette }) => ({
  labelStyles: {
    display: 'flex',
    height: 24,
    alignItems: 'center',
    position: 'relative',
    marginTop: 8,
  },
  errorIcon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inputStyles: {
    display: 'block',
    width: '100%',
    padding: '8px 12px',
    border: (props) =>
      props.hasError
        ? `1px solid ${palette.red.main}`
        : `1px solid ${palette.lightGrey.main}`,
    borderRadius: 2,
    '&:focus': {
      outline: 'none',
      border: `1px solid ${palette.lightGrey.main}`,
    },
  },
  inputWrapper: {
    position: 'relative',
  },
  passwordToggle: {
    textDecoration: 'underline',
    cursor: 'pointer',
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const PasswordToggle = ({ type, toggleDispatcher }) => {
  const { passwordToggle } = useStyles();
  const handleToggle = () => {
    const newType = type === 'password' ? 'text' : 'password';
    toggleDispatcher(newType);
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span
      className={clsx('p3', 'dark-grey', passwordToggle)}
      onClick={handleToggle}
    >
      {type === 'password' ? 'Show' : 'Hide'}
    </span>
  );
};

export const Input = ({
  labelText,
  type,
  required,
  children,
  classes = {
    root: '',
    label: '',
    input: '',
  },
  ...rest
}) => {
  const context = useContext(formContext);
  const { error } = { ...context };
  const hasError = error?.name === rest.name;
  const { labelStyles, inputStyles, errorIcon, inputWrapper } = useStyles({
    hasError,
  });
  const [localtype, setLocalType] = useState(type);
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <label
        className={clsx('p2', 'dark-grey', labelStyles, classes.label)}
        htmlFor={rest.name}
      >
        {labelText}
        {required && <span className="red">*</span>}
        {hasError && <ErrorIcon className={errorIcon} />}
      </label>
      {/* Support styling Stripe Elements */}
      <div className={inputWrapper}>
        {children ? (
          <div className={clsx('p1', 'black', inputStyles)}>{children}</div>
        ) : (
          <input
            type={localtype}
            required={required}
            className={clsx('p1', 'black', inputStyles, classes.input)}
            id={rest.name}
            {...rest}
          />
        )}
        {type === 'password' && (
          <PasswordToggle type={localtype} toggleDispatcher={setLocalType} />
        )}
      </div>
      {type === 'password' && pathname === '/signup' && (
        <Typography
          className={clsx('p1', hasError ? 'red' : 'dark-grey')}
          style={{ lineHeight: '24px' }}
          variant="caption"
        >
          8 characters minimum
        </Typography>
      )}
    </div>
  );
};
