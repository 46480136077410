/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import {
  TableCell,
  TableRow,
  Tooltip,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { TooltipContent } from '@src/layouts/TooltipContent';
import { animated, useSpring } from '@react-spring/web';
import { ReactComponent as LockIcon } from '@assets/icons/ic_locked.svg';
import { ReactComponent as CheckboxOnIcon } from '../../img/checkbox-on.svg';
import { ReactComponent as CheckboxOffIcon } from '../../img/checkbox-off.svg';

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    marginLeft: 6,
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: 14,
    padding: 0,
  },
  tooltipPopper: {
    zIndex: 999999,
  },
  tableCell: {
    fontSize: 16,
    fontWeight: 500,
  },
  checkBox: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
}));

const AnimatedRow = animated(TableRow);

export const ViewerTableRow = (props) => {
  const { data, id, units, toggleLine, isCirc, shouldAnimate = true } = props;
  const classes = useStyles();
  const [value, setValue] = useState(data.value);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [springs, api] = useSpring(() => ({
    x: 0,
    opacity: 1,
    config: { duration: 200, tension: 200, friction: 1 },
  }));
  const [rowSprings, rowApi] = useSpring(() => ({
    x: 0,
    opacity: 1,
    config: { duration: 200, tension: 200, friction: 1 },
  }));

  useEffect(() => {
    if (data.value !== '?') {
      const convertToCm = (i) => Math.round((i / 39.3701) * 10000) / 100;
      if (units === 'cm') {
        api.start({
          x: 0,
          opacity: 1,
          from: { x: -15, opacity: 0 },
        });
        setValue((prev) => convertToCm(prev));
      } else {
        setValue(data.value);
        api.start({
          x: 0,
          opacity: 1,
          from: { x: 15, opacity: 0 },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, data.value]);

  useEffect(() => {
    if (!shouldAnimate) return;
    if (isCirc) {
      rowApi.start({
        x: 0,
        opacity: 1,
        from: { x: 100, opacity: 0 },
      });
    } else {
      rowApi.start({
        x: 0,
        opacity: 1,
        from: { x: -100, opacity: 0 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCirc, shouldAnimate]);

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  return (
    <React.Fragment key={`${data.label}`}>
      <AnimatedRow style={{ ...rowSprings }}>
        <TableCell className={classes.tableCell} colSpan={6}>
          {data.label}
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              popper: classes.tooltipPopper,
            }}
            title={<TooltipContent text={data.hoverText} />}
            open={openTooltip}
          >
            <span
              role="button"
              className={classes.tooltipIcon}
              onMouseEnter={handleOpenTooltip}
              onMouseLeave={handleCloseTooltip}
              onClick={handleOpenTooltip}
            >
              &#9432;
            </span>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.tableCell} align="center" colSpan={2}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <animated.span style={{ ...springs }}>
              {data.value === '?' ? <LockIcon /> : value}
            </animated.span>
          </div>
        </TableCell>
        <TableCell className={classes.tableCell} colSpan={1}>
          <Checkbox
            disableRipple
            className={classes.checkBox}
            checkedIcon={<CheckboxOnIcon />}
            icon={<CheckboxOffIcon />}
            checked={data.isSelected}
            onClick={() => toggleLine(id)}
            id={id}
          />
        </TableCell>
      </AnimatedRow>
    </React.Fragment>
  );
};
