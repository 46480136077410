import { useContext } from 'react';
import { ToasterContext } from '@context/ToasterContext';

export const useToasterContext = () => {
  const context = useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToasterContext must be used within a ToasterProvider');
  }
  return context;
};
