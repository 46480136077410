import { lazy, Suspense } from 'react';
import { useMediaQuery, Box } from '@material-ui/core';
import { ReactComponent as Logo } from '../../img/logo.svg';

const IOSDrawer = lazy(() => import('./IOSDrawer'));

export const IOSDrawerWrapper = (props) => {
  const isMobile = useMediaQuery('(max-width: 1200px)');

  if (!isMobile) {
    const { children } = props;
    return <>{children}</>;
  }

  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Logo width="86px" height="86px" />
        </Box>
      }
    >
      <IOSDrawer {...props} />
    </Suspense>
  );
};
